.des_countdown_wrapper .countdown_content_wrapper{
    display:inline-flex;
    align-items:center;
}
.des_countdown_wrapper .countdown_date_wrapper {
    margin: auto 25px;
}
.countdown_days, .countdown_hours,  .countdown_minutes, .countdown_seconds {
    display: inline-block;
    margin: auto 6px;
}
.des_countdown_close_icon {
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}
.countdown_container{
	display: flex;
    align-items: center;
    justify-content: center;
	background-color: #f3ac3a;
    padding: 10px;
}
.countdown_wrapper{
	display: inline-flex;
    align-items: center;
    justify-content: center;
	width:90%;
}
.headline_text{
	display: inline-block;
	font-size: 16px;
    font-weight: 600;
}
.countdown_number{
	padding: 4px 8px;
    font-size: 16px;
    font-weight: 600;
    color: #003c7d;
	display: inline-block;
    border-radius: 5px;
	background-color: #fff;
    margin:3px;
}
.countdown_text{
	display: flex;
    justify-content: center;
    font-size: 10px;
	margin-top: 5px;
}
.countdown_enroll_now{
	display: inline-block;
	padding: 7px 12px;
	;border-radius: 5px;
	background-color: #f10217;
	vertical-align: middle;
	font-weight: 600;
}
.countdown_enroll_now a{
	color:#fff;
	font-size:16px;
}
.mob_countdown_wrapper, .mob_countdown_close_icon{
	display:none;
}
.mob_countdown_wrapper .mob_countdown_close_icon {
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    float: right;
    color: #fff;
}
.mob_countdown_wrapper .countdown_content_wrapper {
    display:inline-flex;
    align-items:center;
}
.mob_countdown_wrapper .countdown_date_wrapper {
    margin: auto 25px;
}
.subtext {
    font-size:10px;
}
@media only screen and (max-width:850px){
	.desktop_login {
	display:none;
	}
	.countdown_wrapper{
	width:97%;
	}
}
@media only screen and (max-width:700px){
.des_countdown_wrapper, .des_countdown_close_icon{
	display:none;
}
.mob_countdown_wrapper, .mob_countdown_close_icon{
	display:block;
	text-align:center;
}
.countdown_container{
	background-color:#19144a;
}
.countdown_wrapper{
	width:90%;
}
.headline_text{
	color:#fff;
	margin-top: 10px;
	margin-bottom:10px;
}
.countdown_days, .countdown_hours, .countdown_minutes, .countdown_seconds{
	margin:auto 0px !important;
}
.countdown_enroll_now{
	white-space:pre;
	font-size:12px;
	margin-left:8px;
}
.countdown_number{
	padding:6px 8px;
	font-size:14px;
}
.countdown_date_wrapper{
	margin:auto 1px !important;
}
.countdown_close_icon{
	font-size:13px !important;
}
}
@media only screen and (min-width:701px) and (max-width:790px){
.countdown_wrapper{
	width:100%;
}
.countdown_date_wrapper{
	margin: auto 17px !important;
    white-space: nowrap;
}
.countdown_enroll_now{
	white-space: nowrap;
}
}
@media only screen and (max-width:500px){
.countdown_wrapper{
	width:100%;
}
.countdown_enroll_now{
	padding:5px;
}
.countdown_enroll_now a{
	font-size:14px;
}
.countdown_number {
    padding: 4px 4px;
}
}
@media only screen and (max-width:336px){
	.countdown_enroll_now a{
	font-size:12px !important;
}
.countdown_number {
    font-size:12px;
}
}