.payment-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1225px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  clear: both;
}

.main-content {
  width: 70%;
}

.payment-info {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 0px 35px 0px 20px;
}

.loader {
  width: 16px;
  height: 16px;
}

.coupon-remove {
  color: #2c99d2;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.payment-section,
.payment-order-summary {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.payment-info h2,
.payment-section h2,
.payment-order-summary h2 {
  margin-bottom: 20px;
  color: #004b8d;
}

.billing-address {
  display: flex;
}

.payment-shipping-info {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: #828282;
}

.shipping-address,
.shipping-method,
.billing-address,
.payment-method,
.card-expiry-cvc,
.recaptcha {
  margin-bottom: 20px;
}

.payment-method {
  display: flex;
  flex-direction: column;
}

.payment-method-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.payment-method input[type="radio"] {
  margin-right: 10px;
}

.payment-method label {
  display: flex;
  align-items: center;
}

.payment-method label img {
  margin-left: 10px;
}

.payment-page-textbox {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.pay-now-button {
  background-color: #ED202C;
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-size: 18px;  
}


.payment-apply-coupon {
  background-color: #ED202C;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-size: 18px;
}

.pamt-billing {
  padding-left: 8px;
  font-weight: 100;
  margin-bottom: 0px;
}

.paypal-logo {
  width: 40px;
  height: 35px;
  background-size: contain;
}

.credit-card-logos {
  width: 350px;
  height: 30px;
  background-size: contain;
  margin-left: 2% !important;
}

.payment-order-summary {
  width: 30%;
  height: fit-content;
  margin-left: 15px;
}


.card-expiry-cvc {
  display: flex;
  justify-content: space-between;
}

.card-expiry-cvc input {
  width: 48%;
}

.payment-order-summary .order-details .order-item,
.payment-order-summary .order-details .order-shipping,
.payment-order-summary.order-details .order-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.product-details {
  display: block;
  justify-content: space-between;
  font-size:14px;
}
.pay-order-note span {
  font-size:14px;
}
.coupon-details {
  display: flex;
  flex-direction: row;
  color: #707070;
  font-weight: 700;
  justify-content: space-between;
}		  
.set-message-error {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #fef5f1;
  border: 1px solid #ed541d;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  color :#8c2e0b;
  }

.payment-order-summary .coupon-container {
  display: flex;
  margin-bottom: 10px;
}

.payment-order-summary .coupon-container input[type="text"] {
  width: calc(100% - 70px);
  margin-right: 10px;
}

.payment-order-summary .product-details h3 {
  margin-bottom: 10px;
}

.payment-order-summary .pay-order-note p {
  margin-bottom: 10px;
}

.pay-order-note a {
  color: #2c99d2;
  text-decoration: none;
  font-size: 12px;
  font-size: 10px;
}

.pay-last {
  font-size: 10px;
}


.prod-pay {
  color: #004b8d;
}

.payment-hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.pay-now-oder-button {
  background-color: #ED202C;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-size: 18px;
}

.left-space {
  margin-left: 15px;
}

.payment-page-hr {
  border: 0;
  border-top: 1px solid #ddd;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.thankyou-loader {
  width: 250px;
  height: 250px;
}
.conf-group input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;

}

.conf-group label {
  font-weight: normal;
}

.pmt-conf-info {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.conf-badge {
  font-size: 18px;
  color: #003c7d;
}	
.payment-method label img {
  margin-left:0px;
}
.payment-method label {
  display:block;
}
@media (max-width: 1024px) {
  .payment-container {
      flex-direction: column;
  }

  .main-content,
  .payment-order-summary {
      width: 100%;
  }

  .order-summary {
      margin-top: 20px;
  }

  .left-space {
    margin-left: 0px;
  }

  
}

.pay-entering a {
  color: #2c99d2;
  text-decoration: none;
  font-size: 12px;
}

.credit-card-input {
  position: relative;
  display: flex;
  align-items: center;
}

.pmt-type-labels {
    color: #003c7d;
    font-size: 18px;
}

.cc-card {
  padding: 9.5px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
}

.credit-card-input input{
  border-radius: 5px 0px 0px 5px;
}
.payment-method-item {
  margin-bottom: 15px;
}
.sezzle-logo {
  width: 35px;
  height: 35px;
  background-size: contain;
}

.bnpl {
  text-align: left;
  position: absolute;
  margin-left: 27px;
  margin-top: 45px;
}

.sezzle-cont {
  margin-bottom: 35px;
}
.refund-policy h2{
  color: #003c7d;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
  font-weight: 500;
}
.refund-policy {
  margin-bottom:30px;
}
.refund-policy  a {
  color: #2C99D2;
}
@media (max-width: 768px) {
.credit-card-logos {
  width: 275px;
  height: 25px;
  background-size: contain;
}

.payment-shipping-info {
  display: flex;
  flex-direction: column;
}
.payment-page-textbox {
  font-size:14px;
}
.pay-now-oder-button {
  font-weight:bold;
}
}