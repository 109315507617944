.order-details-page-print {
    text-align: left;
    font-family: Verdana, Arial, sans-serif;
    font-size: 1.1em;
  }
  
  .order-details-page-print-header img,
  .order-details-page-print-footer img {
    width: 100%;
  }
  .receipt-heading {
    padding-top:15px;
    padding-bottom:15px;
  }
  .order-details-disc-shipp .discount_row {
    text-align:left;
  }
  .order-details-disc-shipp .discount_value {
    text-align:right;
  }
  .order-details-page-print-footer {
    background-color: #06407A;
    color: #fff;
    height: fit-content;
    font-size: 15.4px;
    border:0px;
  }
  .invoice-heading p{
  font-size:15px;
  }
  .invoice-heading strong{
    font-size:18px;
  }
  
  .order-details-page-print-table {
    width: 100%;
    border-collapse: collapse;
  }
  .order-details-page-print-table .smallfont {
    font-size:13px;
  }
  .order-details-right-side {
    font-size: 18px;
    font-weight: bold;
    line-height: 2.2em;
  }
  .discount_row {
    width:55%;
  }
  .order-details-page-print-table th,
  .order-details-page-print-table td {
    border: none;
    padding: 8px;
  }
  
  .order-details-page-print-table th {
    background-color: #fff;
    color: #06407A;
  }

  .order-details-page-print-table tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  .orderTotal {
    width: 50%;
    background-color: #06407A;
    height: 35px;
    color: #fff;
    font-size: 23px;
  }
  .orderTotalText {
    margin-left:15px;
    font-weight:900;
  }
  .orderTotalValue {
    text-align:right;
    float: right;
    margin-right: 2%;
  }
  .print-modal-cty-shp {
    text-align: right;
    align-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .product-order-invoice-details {
    display: flex;
    justify-content: space-between;
  }

  .line-for-print-page {
    border: none;
    height: 6px;
    color: #06407A;
    background-color: #06407A;
}

.print-page-footer-content {
  display: flex;
  justify-content: space-between;
  padding: 0% 2% 0% 2%;
}

.order-print-copyright {
  text-align: center;
  padding-bottom: 5px;
}

.order-details-disc-shipp {
    font-size: 16px;
}

.order-details-disc-shipp th, .order-details-disc-shipp td {
  border: none;
  padding: 8px;
}

.order-details-disc-shipp td {
  text-align: right;
}
.thankyou-for-business {
  font-size:22px;
}
.president-founder {
  text-align: center;
    width: 30%;
    margin-top: 0px;
}
.print-page-footer-content p {
  margin-top:0px;
  line-height:1em;
}
.print-page-payment-info {
  display: flex;
  justify-content: space-between;
}

.order-details-pnt-img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.print-modal-total-print {
  margin-top: 0;
  width: 100%;
    background-color: #06407A;
    height: 35px;
    color: #fff;
}

.print-modal-total-paymt-info {
  margin-top: 0;
  color: #06407A;
    font-weight: bold;
    font-size: 20px;
}

.print-modal-thanks {
  display: flex;
  justify-content: space-between;
}

.print-thanks-left {
    width: 60%;
}

.print-thanks-right {
    width: 40%;
    text-align: center;
}

.print-cnt-ship-total {
    width: 71%;
    text-align: left !important;
}


@media print {
    .order-details-print-modal {
      display: block;
    }
  
    .order-details-print-modal-content {
      width: 100%;
      background-color: white;
      color: black;
      padding: 20px;
    }
  
    .printpage-close-button, .order-det-print-button {
      display: none;
    }
  
  }