body {
  background: #f6f5f5;
  font-size: 14px;
  font-family: sans-serif, arial, verdana;
  color: #000;
}

.smww-appn {
  border: 1px solid #919090;
  border-radius: 5px;
  height: 34px;
  width: 87.8%;
  padding: 6px 12px;
  font-size: 14px;
}

.form-row {
    display: flex;
    margin-bottom: 20px;
  }
  
  .form-group {
    flex: 1;
    margin-right: 10px;
  }
 
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .province {
    margin-top: 16.1px;
  }
  .application-form-container h1 {
    color: #003c7d;
    border-left: 15px solid #f3ac3a;
    padding-left: 10px;
    font-weight: 400;
    line-height: 1.2;
    font-size: 24px;
    margin-bottom:20px;
  }

  .smww-appn-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .smww-appn-button:hover {
    background-color: #0056b3;
  }
  .form-item-app-highest-grade {
    width:100%;
  }
  .application-form-container {
    margin: 0 auto;
    max-width: 1170px;
    width: 100%;
  }
  .application-form-container .form-container {
    max-width: 865px;
    width: 73%;
  }
  .birthday-inputs {
    display: flex;
  }
  
  .birthday-inputs select {
    width: 27.30%;
  }
  
 .gender-radio {
    display: flex;
    flex-direction: column;
  }
  
  .gender-radio label {
    display: block;
    align-items: center;
    height: 20px;
    font-weight: unset;
  }
  
  .gender-radio input[type="radio"] {
    margin-right: 8px; 
    width: auto;
    height: 12px;
  }
  
  .radio {
    display: inline-block;
    margin-right: 15px;
  }

  .additional-text {
    margin-top: 20px;
    margin-bottom: 20px;
    color: green;
    font-weight: 700;
  }
  

  .radio-option:last-child {
    margin-right: 0; 
  }

  .form-required {
    color: #f00;
}

 .fieldset-legend {
  color: #003c7d;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
  padding-left: 10px;
  border-left: 15px solid #f3ac3a;
  font-weight: 0;
}

.start-appn {
  font-size: 24px;
}

.smww-appn-hr {
  border: 1px solid gray;
  height: 1px;
  margin-top: 20px;
}
.PhoneInput {
  padding-right: 0px;
}

.PhoneInputInput {
  height: 30px;
    width: 100%;
    border: 0px;
    border-radius: 5px;
}

.legend {
  margin-bottom: 20px;
  padding-top: 17px;
  margin-top:0px;
}

.legend1 {
  margin-bottom: 0px;
  padding-top: 0px;
}

.continue-one, .continue-two, .continue-three {
  padding: 10px 20px 11px !important;
  font-size: 21px !important;
  background-color: #188ae2;
  font-weight: bold;
  text-shadow: 1px 1px #188ae2;
  color: #ffffff;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border: 1px solid #188ae2;
  cursor: pointer;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  outline: none !important;
}
.error-message {
  color:red;
  display:block;
			   
}
.box-layout {
  display: flex;
  align-items: center;
}

.box-layout input[type="checkbox"] {
  margin-right: 10px;
  width: auto;

}

.box-layout label {
  font-weight: unset;
}

.edit-courses-group {
  display: block;
  background-color: white;
  padding-bottom: 15px;
  border: 1pt solid #919090;
  border-radius: 5px;
  width: 105%;
  padding-left: 15px;
}




/* -------------------------------------CSS for Interest area------------------------------------ */


.react-tel-input .form-control {
  width: 100% !important;
}


.number {
  width: 87.8% !important;
}

.select-country {
  width: 87.8% !important;
}

.area-interest-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.ai-group {
  display: flex;
  flex-direction: column;
}

.courses-heading {
  font-size: 1.5em;
  color: #003c7d;
  margin-bottom: 20px;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
}

.ai-column1, .ai-column2, .ai-column3 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.category {
  margin-bottom: 20px;
}

.dollar-img {
  width: 10.5px !important;
  height: 21px !important;
  margin-left: 4px;
    margin-right: 9px !important;
}

.category img {
  width: 23px;
  height: 23px;
  vertical-align: middle;
  margin-right: 1px;
}

.category h4 {
  display: inline;
  vertical-align: middle;
  font-size: 15px;
  margin: 0;
  color: #003c7d;
}

.category label {
  font-weight: normal;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
}

.appnd_inter {
    border-left: 15px solid #E8AE52 !important;
    padding: 2px;
    padding-left: 6px !important;
}


.edit-courses-group h3 {
  color: #153D79;
  border-left: 15px solid #E8AE52 !important;
  padding: 2px;
  padding-left: 6px !important;
  font-size: 15px !important;
  margin-top: 20px !important;
}

/*---------------------------------END   CSS for Interest area ----------------------------------------------*/

.appn-bdy {
  margin-left: 12px;
}



.story-text-area {
  height: 120px;
  width: 864px;
}

.form-item {
  width: 50%;
}

.form-item-app-highest-grade {
  clear: both;
  
}

.radio-container {
  display: flex;
  align-items: center;
}

.radio-container > div {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Adjust spacing between radio button groups */
}

.radio-container input[type="radio"] {
  margin-right: 8px; /* Adjust spacing between radio button and label */
}

.radio-container label {
margin-bottom: unset;
}

.linkedin-url {
  width: 50%;
}

.disclaimer-txt {
  margin-top: 26px;
  margin-right: 9%;
  margin-bottom: 3%;
}

.disclaimer-txt a {
  color: #2c99d2;
  text-decoration: none;
}

.bottom-span {
  font-size: 10px;
}

.starting-paragraph {
  margin-bottom: 1em;
}
.application-form-container .radio-container>div {
  margin-right: 30px;
}
.application-form-container .radio-container input[type=radio] {
  height:20px;
}
.react-dropdown-select-input {
  font-size:14px !important;
}
@media only screen and (max-width: 850px) {
  .application-form-container .form-container {
    width: auto;
    margin: 3%;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  body {
    background-color: #fff;
    color: #333;
    font-family: sans-serif, arial, verdana;
    font-size: 16px;
}

  .form-container {
    max-width: 815px;
    margin-left: 2%;
}

.application-form-container {
  margin: 0 auto;
  width: 96%;
}



}



@media only screen and (max-width: 500px) {

  .form-row {
    display: block;
    margin-bottom: 0px;
    margin-left: 3%;
}
.application-form-container .form-group {
  margin-bottom: 1em;
}
.form-container {
  max-width: 854px;
  margin-left: 3%;
}
.select-country {
  width: 100% !important;
}
.smww-appn {
  width: 100%;
}

.edit-courses-group {
  width: 100%;
}

.story-text-area {
  width: 100%;
  margin-top: 5px;
}

.form-item-app-highest-grade {
  clear: both;
  width: auto;
}

.form-item {
  width: auto;
}

.linkedin-url {
  width: auto;
}

body {
  background: #fff;
  color: #333;
  font-size: 16px;
}

.fieldset-legend {
  font-size: 24px;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
}
.checkbox-item input[type="checkbox"] {
  margin-top: 0px !important;
}
.category label {
  font-size: 15px;
}
.form-group label {
  font-size:14px;
}
.continue-one, .continue-two, .continue-three {
  margin-left:3%;
}
.disclaimer-txt {
  margin-left:3%;
}
.form-container p {
  font-size:16px;
  line-height: 1.375;
}
.form-item-app-highest-grade {
  width:100% !important;
}
}