.order-details-page-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.order-details-page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.order-details-page-left-header,
.order-details-page-right-header {
  display: flex;
  flex-direction: column;
}

.order-details-page-left-header a,
.order-details-page-right-header a {
  text-decoration: none;
  color: #2C99D2;
  margin-bottom: 10px;
}

.order-details-page-content {
  display: flex;
}

.order-details-page-main {
  flex: 1;
  margin-right: 20px;
}

.order-details-page-section {
  margin-bottom: 30px;
}

.m-top {
  margin-top: 50px;
}

.order-details-page-section h2 {
  background-color: #fefefe;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
  text-align: left;
  margin-bottom: 0;
  border-bottom: unset;
}

.order-details-page-table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.order-details-page-table th,
.order-details-page-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}


.order-details-page-quick-links {
  width: 200px;
}

.order-details-page-quick-links h3 {
  color: #003c7d;
  font-size: 18px;
  margin-bottom: 10px;
  padding-left: 10px;
  border-left: 15px solid #f3ac3a;
}

.order-details-page-quick-links ul {
  list-style: none;
  padding: 0;
}

.order-details-page-quick-links li {
  margin-bottom: 5px;
}

.order-details-page-quick-links a {
  text-decoration: none;
  color: #2C99D2;
}

.order-details-page-info {
  display: flex;
  justify-content: space-between;
}

.order-details-page-box {
  width: 45%;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.order-details-page-box h3 {
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  padding-left: 15px;
}

.od-ship-bill-info {
  padding-left: 15px;
  line-height: 0.5 !important;
}
.od-ship-bill-info p {
  line-height: 0.1 !important;
}
.panelbody {
  padding: 15px;
  border: 1px solid #ddd;
}

.od-table-panel {
  background-color: #fefefe;
}

.od-table-totals {
  text-align: right !important;
}


.order-details-page-print {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.order-details-page-print-header img,
.order-details-page-print-footer img {
  width: 100%;
  display: block;
}

.order-details-page-print-content {
  margin-top: 20px;
}


.order-details-page-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.order-details-page-table th {
  background-color: #f9f9f9;
}

.survey-table tr:nth-child(odd) {
  background-color: #f9f9f9;
}


.survey-table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.survey-table th,
.survey-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}


.conf-name-badge {
  line-height: 2.1;
}

.conf-name-badge-hr {
  border: 1px solid gray;
  height: 1px;
  margin-bottom: 10px;
  margin-top: 4%;
}

.order-details-print-header {
  display: flex;
  justify-content: space-between;
  border: 1px solid #aaaaaa;
  background: #cccccc;
  color: #222222;
  font-weight: bold;
  line-height: 0;
  padding-left: 10px;
  cursor: move;
}


/* Modal styles */
.order-details-print-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-details-print-modal-overlay {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.order-details-print-modal-content {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  max-width: 900px;
  text-align: center;
  height: -webkit-fill-available;
  overflow: overlay;
  padding: 2px;
  z-index: 2;
}

.print-button {
  cursor: pointer;
}

.printpage-close-button {
  cursor: pointer;
  position: relative;
  top: 13px;
  right: 20px;
  font-size: 17px;
  font-weight: 900;
}

.order-det-print-button {
  text-align: left;
  padding-left: 10px;
  padding-top: 7px;
}


/* Mobile view */
@media (max-width: 768px) {
  .order-details-page-container {
    padding: 10px;
  }

  .order-details-page-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-details-page-content {
    flex-direction: column;
  }

  .order-details-page-quick-links {
    width: 100%;
    margin-top: 20px;
  }

  .order-details-page-info {
    flex-direction: column;
  }

  .order-details-page-box {
    width: 100%;
    margin-bottom: 20px;
  }
}


@media print {
  .order-details-print-modal {
    display: block;
  }

  .order-details-print-modal-content {
    width: 100%;
    background-color: white;
    color: black;
    padding: 20px;
  }

  .printpage-close-button, .order-det-print-button {
    display: none;
  }

  .order-details-print-header {
    display: none;
  }

 
}