.cart-tooltip {
  position: absolute;
  top: 90px;
  right: 0;
  width: 32%;
  background: #fff;
  padding: 5px;
  background: white;
  box-shadow: 0 1px 15px 15px rgba(3, 3, 3, .045);
  border-bottom: 1px solid #d1d1d1;
  border-radius: 8px;
  overflow: hidden;
  z-index: 9999;
}
.cart-tooltip-header .cart-icon-inside {
  color: #515783;
  font-size: 24px !important;
  margin-right: 7px;
  float: left;
  padding: 0px;
}
.cart-tooltip-header .badge {
    background-color: #6394F8;
    border-radius: 10px;
    color: white;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}
.cart-tooltip-course {
  text-align: left;
}

.tooltip-cart-price {
  color: #778191;
  font-weight: 700;
  text-align: right;
  margin-left:auto;
}

.cart-tooltip-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.cart-tooltip-header button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.cart-tooltip-content {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
}

.cart-tooltip-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.cart-tooltip-empty {
  text-align: center;
  padding: 20px;
  color: #888;
}

.cart-tooltip-footer {
  padding: 10px;
}

.cart-tooltip-total {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.cart-tooltip-buttons {
  display: flex;
  justify-content: space-between;
}

.view-cart-button, .checkout-button {
  background-color: #ED202C;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  padding: 7px 24px;
}

.view-cart-button:hover, .checkout-button:hover {
  background-color: #e03b3b;
}


.cart-tooltip-delete {
  padding-left: 10px;
  color: #082330;
  font-size: 12px;
}

.cart-tooltip-delete:hover {
color: #008CBA;
}

@media only screen and (max-width: 500px) {
  .cart-tooltip {
    top: 118px;
    width: 100%;
}


}  