.set-message-error .fa {
    color:red;
}
.set-message-error p {
line-height:2px;
}
.set-message-error img {
    margin-right:1%;
    vertical-align: middle;
}
.set-message-error a {
    color:#000;
}
.set-message-error{
    display:block !important;
}
.set-message-error div {
    width:100%;
}