/* ReferralPage.css */

.referral-page {
  background-color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 20px;
}

.head_title {
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  margin: 10px 0 50px 0;
}

.referral-page .tabs {
  border: 1px solid #2d8cff !important;
  border-radius: 5px;
}

.referral-page .tabs button {
  background-color: transparent;
  border: none;
  font-size: 17px;
  cursor: pointer;
  padding: 12px 16px;
  transition: 0.3s;
  color: #2d8cff;
  border-right: 1px solid rgba(45, 140, 255, .5);
}

.tabs button {
  width: 25% !important;
}

.referral-page .tabs button:focus,
.referral-page .tabs button.active {
  color: #fff;
  background-color: #2d8cff;
}

.overview-container {
  padding: 20px 12px;
}

.commonHeading {
  color: #000;
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
  margin: 50px auto;
  font-weight: 400;
}

.s_box {
  float: left;
  margin: -30px 0 0 0;
  background: #EEF0F2;
  width: 100%;
  height: 175px;
}

.s_box ul {
  float: left;
  margin: 50px 0 0 0px;
  padding: 0 0;
  text-align: center;
  width: 100%;
  margin-top: 39px;
}

.invit, .earn, .redeem {
  color: #626263 !important;
  font-size: 14px;
  float: left;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}
.invit, .earn, .redeem {
  margin: 30px 0px;
}


.referral-page .overview {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  text-align: center;
}

.referral-page .overview-item {
  max-width: 250px;
  width: 100%;
  margin: 10px auto;
  overflow: hidden;
}

.referral-page .overview-item h2 {
  position: relative;
    font-size: 35px;
    font-weight: 600;
    color: #FFF;
    background: #ff7800;
    padding: 15px;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    line-height: 40px;
    text-align: center;
    margin: 0px auto;
    border: none;
}

.redeem-image {
  margin-top: 10%;
}

.s_box img {
  vertical-align: middle;
}

.referral-page .overview-item .title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.referral-page .overview-item .description {
  font-size: 14px;
  color: #666;
}

.overview-hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}


.referral-page .share-earn {
  margin-top: 20px;
  padding: 3%;
}

.referral-page .share-earn .sub-tabs {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
}

.referral-page .share-earn .sub-tabs button {
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  width: calc(50% - 3px);
  background: #C6D0DA none;
  text-align: center;
  padding: 12px;
  font-size: 13px;
  border-top-left-radius: 4px;

}

.referral-page .share-earn .sub-tabs button.active {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
  margin-right: 4px;
  margin-left: 2px;
}

.referral-page .social,
.referral-page .email {
  margin-top: 20px;
}

.social-container {
  background: #f1f1f1;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  padding: 20px 12px;
}

.share-refr-Social {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

.share-refr-Social img {
  width: 200px;
  height: 45px;
  margin-bottom: 10px;
}

.social-copyLink {
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  text-align: center;
}

.social-copyLink input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}

.refr-cust-hr {
  border-top: 2px solid #003c7d;
  margin-top: 35px;
  margin-bottom: 30px;
}


.referral-page .customize-code button {
  text-align: center;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  appearance: none;
  outline: 0;
  -webkit-appearance: none;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 35px;
  background-color: #ED202C;
  color: #fff;
  padding: 7px 24px;
  vertical-align: bottom;
}

.cust-reff-code {
  display: flex;
  justify-content: center;
  gap: 10px;
}


.referral-page .customize-code {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
}

.customize-code h2 {
  color: #003c7d;
  font-size: 18px;
    text-align: center;
    margin-top: 5px;
}

.referral-page .customize-code p {
  margin-top: 10px;
}

.referral-page .customize-code input {
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
}

.referral-page .customize-code button {
  margin-top: 10px;
}

.referral-page .terms-conditions {
  margin-top: 20px;
  padding: 20px 12px;
}


.referral-page .my-balance {
  margin-top: 20px;
}

.statsTitle {
  margin-top: 39px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  clear: both;
  padding-top: 45px;
}

.my_balance_container {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  height: 135px;
  background: #EEF0F2;
  text-align: center;
}

.balance-panel-default {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    padding: 5px 0px 5px 15px;
    margin-top: 25px;
}

.ref-bal-desc {
  margin-top: 50px;
  margin-bottom: 30px;
}

.terms-and-conditions-para {
  line-height: 1.375;
  margin: 0 0 10px;
}
.referral-page table {
  width:100%;
}
.referral-page table tr:nth-child(even) {
  background-color: #fff;
}

.referral-page table tr:nth-child(odd) {
  background-color: #f9f9f9;
}