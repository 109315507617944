.talk-form-container {
  width: 100%;
  max-width: 1170px;
  height: auto;
  margin: 0 auto;
  min-height: 450px;
  margin-bottom: 3%;
  clear: both;
}

.talk-fields input, .talk-fields select, .talk-fields textarea {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 2px 2px 3px #666;
  font-size: 15px;
  padding: 4px 7px;
  outline: 0;
  appearance: none;
  margin-bottom: 5px;
  width: 98%;
}

.talk-form-container h1 {
  color: #003c7d;
  border-left: 15px solid #f3ac3a;
  padding-left: 10px;
  margin-bottom: 20px;
  font-size: 24px;
}

.talk-fields {
  margin-bottom: 10px;
}

.talk-fields textarea {
  height: 136px;
}

.talk-form-left {
  width: 26%;
}

.paragraph-image {
  width: 228px;
  padding-top: 13px;
  height: 355px;
}

.paragraph-image-container {
  margin-bottom: 20px;
  display: flex;
}

.paragraph-image-container p {
  margin-right: 20px;
  max-width: 730px;
}

.opening-paragraph {
  float: left;
}

.opening-paragraph p {
  font-size: 11pt;
  font-family: Arial;
  color: rgb(0, 0, 0);
  margin-bottom: 10px;
  line-height: 1.375;
}

.guidance {
  font-size: 11pt;
  font-family: Arial;
  color: rgb(0, 0, 0);
  background-color: transparent;
  font-style: italic;
  vertical-align: baseline;
  white-space: pre-wrap;
}

.talk-with-lynn-btnn {
  margin: 12px 0 0 0;
  width: 100%;
  display: block;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 19px !important;
  line-height: 25px;
  border: 3px solid #fff;
  box-shadow: 0px 0px 8px #999;
  border-radius: 10px;
  padding: 8px 10px;
  background: #fe0000;
  background: -moz-linear-gradient(top, #fe0000 0%, #b60000 100%);
  background: -webkit-linear-gradient(top, #fe0000 0%, #b60000 100%);
  background: linear-gradient(to bottom, #fe0000 0%, #b60000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fe0000', endColorstr='#b60000', GradientType=0);
}

.form-image-container {
  display: flex;
  flex-wrap: wrap;
}

.form-right-image {
  width: 53.5%;
  margin-left: 5%;
  margin-top: 13px;
}

.form-bigimage-right {
  width: 625.94px;
  height: 625.94px;
}

@media only screen and (max-width: 600px) {
  .form-image-container {
    display: flex;
    flex-direction: column;
  }

  .paragraph-image-container {
    flex-direction: column;
  }

  .paragraph-image {
    order: 1;
    margin: 0 auto;
    width: 277px;
    height: 416px;
  }

  .form-right-image {
    order: 1;
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }

  .opening-paragraph {
    order: 3; 
    width: 100%;
  }

  .talk-form-left {
    order: 2; 
    width: 98%;
    margin-left: 7px;
  }

  .bullet-point {
    list-style: unset;
  }

  .form-bigimage-right {
    width: 396.98px;
    height: 396.98px;
  }

  .paragraph-image-container p {
    margin-left: 20px;
  }

  .talk-with-lynn-btnn {
    width: 99%;
  }

  .talk-form-container h1 {
    margin-left: 10px;
}

}
