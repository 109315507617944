.adv-cert-container {
  font-family: Arial, sans-serif;
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
  margin-bottom: 20px;
  margin-top:20px;
}

.full-width-header {
  background-color: #f3ac3a;
  color: black;
  font-size: 24px;
  text-align: center;
  width: 100%;
}

.full-width-header h2 {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  color: black !important;
  display: inline-block;
  width: 100%;
}

.adv-cert-header h1 {
  font-size: 30px;
  color: white;
  
  margin-left: 40px;
  display: inline-block;
  border-left: 15px solid #f3ac3a;
  padding-left: 10px;
}

.advanced-certificates {
  display: flex;
  position: relative;
  padding-top: 90px; 
}

.adv-cert-header {
  background-color: #003c7d;
  color: white;
  font-size: 20px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  position: absolute;
  top: 20px; /* Adjust as needed */
  z-index: 1;
  margin-top: 20px;
}

.adv-cert-header-second {
z-index: 0;

}
.adv-cert-content {
  display: flex;
  width: 100%;
  background-color: #fff;
  padding-right: 20px;
  height: auto;
  box-shadow: 0 6px 16px -9px;
}

.adv-cert-text {
  flex: 2;
  padding: 30px 0px 0px 35px;
}

.adv-cert-text ul {
  list-style-type: none;
  padding: 0;
  line-height: 28px;
}

.adv-cert-text ul li {
margin-bottom: 10px;
margin-left: 20px;
font-size: 16px;
margin-top: 10px;
line-height: 21px;
}

.description_class {
  
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.375;
  color: #333;
}

.adv-cert-text ul li:before {
content: "\2022";
  color: #f3ac3a;
  display: list-item;
  width: 19px;
  font-weight: 700;
  font-size: 2.5rem;
  float: left;
}

.faq-button {
  color: #fff !important;
  width: 185px;
  height: 40px;
  margin-bottom: 25px;
  border: 3px solid #fff;
  border-radius: 10px !important;
  background: -webkit-linear-gradient(top, #fe0000 0%, #b60000 100%);
  font-size: 18px !important;
  font-weight: bold;
  box-shadow: 0px 0px 8px #999;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.faq-button:hover {
  color: #000000 !important;
}

.adv-cert-image-container {
  flex-basis: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.adv-cert-image {
  width: 412px;
  height: 530px;
  cursor: pointer;
  z-index: 0;
  position: relative;
  top: -68px;
  box-shadow: 0px 0px 25px -9px;
  border-radius: 5px;
  transition: 0.3s;
}

.adv-cert-image-second{
z-index: 0;
}
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
}

.adcert-close-button {
position: absolute;
top: 85px;
right: 35px;
color: #f1f1f1;
font-size: 40px;
font-weight: bold;
transition: 0.3s;
cursor: pointer;
}
.ad-modal .modal-body {
height:230px;
overflow-y: scroll;
}
.ad-modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
z-index: 1000;
}

.ad-modal-overlay h2 {
text-align: center;
  font-weight: bold;
  font-size: 22px;
  max-width: 55%;
  margin: 0 auto;
  /* padding-bottom: 25px; */
}

.ad-modal {
background: white;
padding: 15px;
padding-top: 35px;				
border-radius: 30px;
width: 80%;
max-width: 650px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
position: relative;
text-align: center;
padding-bottom: 30px;
}

.adm-close-button {
position: absolute;
top: 10px;
right: 10px;
background: none;
border: none;
font-size: 40px;
cursor: pointer;
color: #A9A9A9;
opacity: 2;
padding: 4%;
font-weight: normal;
line-height: 0;
}

.modal-badge {
width: 38%;
height: auto;
}
.ad-modal-form {
text-align: left;
margin: 0 auto;
max-width: 65%;
}


.ad-modal-form label {

font-size: 1.0em;
  padding: 18px 0px 18px 22px;
  /* border-bottom: 1px solid #D0DBEB; */
  font-weight: normal;
}


.modal-continue-button {
text-align: center;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  appearance: none;
  outline: 0;
left:20px;
  -webkit-appearance: none;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 35px;
  background-color: #ED202C;
  color: #fff;
  padding: 7px 24px;
  vertical-align: bottom;
  width: 85%;
  margin-top: 25px;
}


.ad-modal-form input[type=radio] {
position: absolute;
width: 100%;
  opacity: 0;
  top: 18px;
  
  z-index: 6;
  cursor: pointer;
}
.product-list label {
display: block;
  position: relative;
  font-weight: 300;
  font-size: 14px;
  padding: 18px 0px 18px 22px;
  margin: 0px auto;
  /* height: 30px; */
  /* z-index: 9; */
  cursor: pointer;
  
}
.product-list li {
  display: block;
  position: relative;
  float: left;
  right: 20px;
  width: 100%;
  border-bottom: 1px solid #D0DBEB;
}
.product-list li input[type=radio]:checked ~ .check::before {
background: #0D47A1;
}
.product-list li .check::before {
display: block;
position: absolute;
content: '';
border-radius: 100%;
height: 10px;
width: 10px;
top: 3px;
left: 3px;
margin: auto;
transition: background 0.25s linear;
-webkit-transition: background 0.25s linear;
}
.product-list li:hover label {
color: #0D47A1;
}
/* Media Query for Mobile Devices */
@media (max-width: 850px) {
.modal-badge {
  width:50%;
}

  .adv-cert-content {
      flex-direction: column;
      padding: 20px;
      height: auto;
  }
  .adv-cert-container {
    margin: 0 auto;
    margin-top: 5%;
      padding-left: 2%;
      padding-right: 2%;
  }
  
  .adv-cert-text {
      padding: 20px;
      padding: 0px;
  
  }
  .adv-cert-image-container {
    margin-top:20px;
    display: block;
  }
  .description_class {
      width: 100%;
      padding-top:0px;
      margin-top:0px;
  }

  .adv-cert-image {
      width: 100%;
      height: auto;
      top: 0;
  
  }

  .adv-cert-header {
      top: 20px;
      margin-top: 0;
  
  }
 .adv-cert-header-second {
    top: 20px;
    margin-top: 0;
    padding: 0px 10px;
    
}
  .adv-cert-header h1 {
             
      font-size: 22px;
      margin-left: 20px;
  
  }

 .adv-cert-header-second h1 {
    font-size: 5.2vw !important;
  }

  .faq-button {
      width: 100%;
  }
}



.cert-info {
  max-width: 60%;
}

.cert-info h2 {
  color: #ff8000;
}

.cert-info ul {
  list-style: none;
  padding: 0;
}

.cert-info ul li {
  margin-bottom: 10px;
}

.cert-info .faq-button {
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.cert-image img {
  height: 532px;
  width: 420px;
}




.tile-img {
  width: 90%;
  margin: 0 auto;
  display: block;
  border: 5px solid #fff;
  border-radius: 15px;
  box-shadow: 0 6px 16px -9px;
  position: relative;
  margin-top: 4%;
}

.badge-img {
position: absolute;
right: 24%;
width: 48%;
top: 10%;
}


.pick-specialty {
  text-align: center;
  margin: 40px 0;
}

.card-header {
background-color: #003c7d;
  color: white;
  font-size: 20px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
}


.card-header h1 {
font-size: 20px;
font-weight: bold;
word-break: break-all;
color: white;
  margin-top: 20px;
  margin-left: 13px;
  display: inline-block;
  border-left: 15px solid #f3ac3a;
  padding-left: 10px;
}

.package-content {
line-height: 25px;
  text-align: center;
  padding: 4%;
  margin-left: 2%;
  clear: both;
  padding-right: 5%;
  padding-top: 17px;
}

.package-content a {
font-size: 18px;
color: #2c99d2;
text-decoration: none;
}

.package-content a:hover {
color: #000000;
}

.more_link a {
font-size: 16px !important;
color: #2c99d2;
}

.more_link a:hover {
color: #000000;
}


.specialty-cards {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
gap: 30px;
margin-top: 40px;
}

.specialty-card {
  width: 340px;
  height: 580px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.specialty-card img {
  max-width: 100%;
  border-radius: 5px;
}

.specialty-card h3 {
  font-size: 22px;
  margin: 20px 0 10px;
  text-align: center;
}

.specialty-card p {
  font-size: 16px;
  color: #666;
  text-align: center;
}

.specialty-card button {
color: #fff !important;
margin-bottom: 15px;
border: 4px solid #fff;
border-radius: 10px !important;
background: -webkit-linear-gradient(top, #fe0000 0%, #b60000 100%);
font-size: 18px;
box-shadow: 0px 0px 8px #999;
max-width: 100%;
width: 291px;
height: 42px;
font-weight: 700;
cursor: pointer;
}

.specialty-card button:hover {
  background-color: #002a5c;
}
.sta-pay {
float: right;
  font-weight: 600;
  padding-right: 9px;
}
/* Responsive Styles */
@media (max-width: 1200px) {
  .specialty-card {
      width: 45%;
  }
}

@media (max-width: 850px) {
  .specialty-card {
      width: 100%;
      margin-bottom: 22px;
  }
}



.substitute p {
font-size: 19px;
  color: #333;
}


.low-rate {
border: 2px solid #f3ac3a;
margin-top: 5%;
}

.low-rate p {
text-align: center;
  padding: 5px 0;
  font-size: 59px;
  margin: 0px 2px;
  background: #fff;
  display: flex;
  align-items: baseline;
  flex-direction: row;
  justify-content: center;
}

.first_rate {
background-image: repeating-linear-gradient(163deg, transparent 0%, transparent 48%, red 50%, transparent 53%, transparent 100%);
}

.new-rate {
font-weight: 900;
color: red;
}



.linkedin-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.linkedin-info {
  max-width: 50%;
  border: none;
  box-shadow: 0 6px 16px -9px;
  width: 100%;
  padding: 2%;
  background-color: #fff;
  padding-left: 4%;
  padding-right: 4%;
  height: 760px;
}

.linkedin-info h2 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #003c7d;
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: center;
}

.linkedin-badge-img {
text-align: center;
margin: 60px 0;
vertical-align: middle;


}

.linkedin-badge-img img {
width: 175px;
height: 150px;

}

.linkedin-info p {
text-align: center;
  line-height: 30px;
  font-size: 16px;
}



.linkedin-form {
  background: #003c7d;
  border: none;
  box-shadow: 0 6px 16px -9px;
  width: 100%;
  padding: 2%;
  padding-left: 4%;
  padding-right: 4%;
}

.linkedin-form h2 {
  font-size: 37px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 10px;
}

.linkedin-form p {
  text-align: center;
  font-size: 20px;
  color: #fff;
}

.linkedin-form form {
  display: flex;
  flex-direction: column;
}


.linkedin-form select,
.linkedin-form textarea {
  margin-bottom: 20px;
  padding: 2%;
  border: none;
  border-radius: 5px;
}

.linkedin-form input {
  margin-bottom: 20px;
  padding: 2.5%;
  border: none;
  border-radius: 5px;
}  

.linkedin-form button {
  width: 100%;
  padding: 2%;
  cursor: pointer;
  border: none;
  margin: 2% 0;
  color: #fff;
  height: 45px;
  border-radius: 10px !important;
  background: -webkit-linear-gradient(top, #fe0000 0%, #b60000 100%);
  font-size: 18px;
}

.linkedin-form button:hover {
  color: #000000;
}

.recaptcha {
  margin-bottom: 10px;
}
.linkedin-form .select-country {
  width:100% !important;
  margin-bottom: 5%;
}
.linkedin-form .react-tel-input {
  width:100% !important;
  margin-bottom: 5%;
}
.linkedin-form .success_message_wrapper {
  text-align:center;
  color:#fff;
}
.ad-sucs-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #A9A9A9;
  opacity: 2;
  padding: 2%;
  font-weight: normal;
  line-height: 0;
}


.add-sucs p {
  font-size: 14px;
  font-weight: 700;
  padding: 0px 14px 16px 0px;
  padding-left: 16px;
    color: #000;
    border-bottom: 1px solid;
}

.adv-success-message {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 50%;
  max-width: 850px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  border-radius: 15px;
  padding-top: 0px;
}

@media (max-width: 600px) {
  .adv-success-message{
    width: 100%;          
  }

}

.adv-message-text p {
  font-size: 14px;
  margin-bottom: 15px;
  color: #234600;
  line-height: 1.375;
  margin:0;
  
}

.adv-message-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f8fff0;
  border: 1px solid #be7;
}

.adv-message-icon {
  font-size: 22px;
  color: green;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  bottom: 30px;
}


.adv-success-buttons {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: flex-start;
}

.adv-success-buttons .adv-view-cart-button,
.adv-success-buttons .adv-checkout-button,
.adv-success-buttons .adv-close-button {
  
  background-color: #00c0ef;
  color: white;
  border: none;
  padding: 8px 22px;
  margin: 0 5px;
  border-radius: 35px;
  cursor: pointer;
  margin-bottom: 35px;
}

.adv-success-buttons .adv-view-cart-button:hover,
.adv-success-buttons .adv-checkout-button:hover,
.adv-success-buttons .adv-close-button:hover {
  background-color: #009acd;
}
.success_message_wrapper {
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: 43%;
  font-size: 16px;
  color: rgb(255, 255, 255);
}

.adv-view-cart-button, .adv-checkout-button {
  width:110px;
  /* margin-top:2% !important; */
}
.adv-close-button {
  width: 85px;
}




@media (max-width: 850px) {
  .full-width-header h2 {
    font-size: 5vw !important;
              
  }

  .linkedin-container {
      display: flex;
      flex-direction: column;
  }

  .linkedin-info {
    max-width: 100%;
  }

  .low-rate p {
      font-size: 9.9vw !important;
      text-align: center;
  }

  .mob-low {
    font-size: 25px;
    text-align: center;
  }


}  
@media (max-width: 600px) {
  .ad-modal {
    width:95%;
    
  }
  .ad-modal-overlay h2 {
    max-width:100%;
    padding-bottom: 0px;
  }
  .ad-modal-form {
    max-width:100%;
  }
  
  .adv-success-message .adv-message-content .adv-message-text p {
    line-height: 1.3;
  }
  .adv-view-cart-button, .adv-checkout-button {
    width:110px;
    /* margin-top:2% !important; */
  }
  .adv-close-button {
    width: 85px;
  }
  .linkedin-info {
    margin-bottom:20px;
    height:650px;
  }
}