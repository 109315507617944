.edit-profile-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .edit-profile-row {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .edit-profile-row label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  

  .edit-profile-row input {
    width: 460px;
    border: 1px solid #ccc;
    font-size: 16px;
  }

  .edit-profile-row select {
    border: 1px solid #ccc;
    font-size: 16px;
    width: 270px;
  }

  .edit-profile-row textarea {
    border: 1px solid #ccc;
    font-size: 16px;
    width: 100%;
    height: 80px;
  }

  
  .edit-profile-row input[type="file"] {
    border: none;
  }
  
  
  .edit-profile-row button:hover {
    background-color: #0056b3;
  }
  
  .edit-profile-row div {
    flex-direction: column;
    gap: 10px;
  
  }
  
  .edit-profile-radio-group {
    display: flex;
    flex-direction: column;
    margin-left: 20px; 
  }

  .edit-profile-radio-group input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: auto;
  }
  
  .edit-profile-radio-group label {
    font-weight: normal;
  }

  .edit-profile-desc {
    font-size: 0.85em;
  }

  .edit-profile-desc a {
    font-size: 14px;
    color: #2C99D2;
    text-decoration: none;
    cursor: pointer;
  }
  

  .edit-profile-submit-button {
    background: #222 url(http://newsmww.preferati.net/sites/all/themes/smww/css/overlay.png) repeat-x;
    background-color: #004b85;
    display: inline-block;
    padding: 7px;
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    position: relative;
    cursor: pointer;
    border: 1px;
    width: 80px;
  }

  .edit-profile-photo {
    width: 95px;
    height: 100px;
}

.edit-profile-password-suggn {
  padding: 0.2em 0.5em;
    margin: 0.7em 0;
    width: 38.5em;
    border: 1px solid #B4B4B4;
    font-size: 0.85em;
}

.edit-profile-password-suggn li {
  list-style: unset;
}

.edit-profile-password-box {
  width: 214px !important;
}


.edit-profile-password-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.edit-profile-password-strength {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.edit-profile-password-strength span {
  font-weight: bold;
  margin-bottom: 5px;
}

.strength-bar {
  width: 100px;
  height: 10px;
  border-radius: 5px;
  background-color: #ccc;
}

.strength-bar.weak {
  background-color: red;
}

.strength-bar.fair {
  background-color: orange;
}

.strength-bar.strong {
  background-color: green;
}
.date-picker input {
  width: 155px !important;
}
.date-picker {
  display: inline-flex;
}
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 155px !important;
}
