.footer .r {
    text-align: right;
    color: #f6f5f5;
    font-family: sans-serif, arial, verdana;
    margin-right: 15px;
}

#footer {
    margin-bottom: 0px;
}

#footer {
    width: 100%;
    background: #555;
    margin: 0 auto 0 auto;
}

.footer {
    padding-right: 10px;
    padding-left: 10px;
}

.footer {
    min-height: 149px !important;
}


.footer {
    width: 100%;
    max-width: 1170px;
    min-height: 129px;
    padding-top: 25px;
    font-size: 12px;
    color: #666;
    margin: 0 auto 0 auto;
    line-height: 2em;
    text-align: center;
}

.footer .l {
    text-align: left;
    color: #f6f5f5;
    font-family: sans-serif, arial, verdana;
}

.footer .blogo {
    background: url(https://www.sportsmanagementworldwide.com/sites/all/themes/smww/images/new_blogo.png) no-repeat;
    float: right;
    height: 103px;
    margin-top: -30px;
    max-width: 103px;
    position: relative;
    right: 0;
    width: 15%;
}

.footer .l a {
    color: #f6f5f5;
    font-family: sans-serif, arial, verdana;
    font-size: 12px;
}

.footer .m {
    text-align: left;
    color: #f6f5f5;
    font-family: sans-serif, arial, verdana;
}

#footer{
margin-bottom: 0px;
margin-top: 10px;
}

.footer-newsletter-form {
    overflow: 'auto';
    max-height: '100%';
}

.footer-newsletter-form input {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    border-radius: 5px;
}

.footer-newsletter-form select {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    border-radius: 5px;
}


.footer-newsletter-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: .4s;
    animation-name: animatetop;
    animation-duration: .4s;
  }

.footer-newsletter-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer-newsletter-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 650px;
    width: 100%;
    position: relative;
    overflow: auto;
    max-height: 85%;
  }
  
  .footer-newsletter-close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    font-size: 30px;
    font-weight: 500;
    line-height: 0;
    padding: 2%;
}
  .footer-newsletter-submit {
    width: 100%;
    background-color: #0078c1;
    border: 0;
    color: white;
    padding: 10px 20px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .footer-newsletter-checkbox-group {
    margin: 10px 0;
  }
  
  .footer-newsletter-checkbox-group input {
    margin-right: 5px;
    width: auto;
    height: auto;
    padding: 0;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    margin-bottom: 6px;
    border-radius: 5px;
}

.footer-newsletter-checkbox-group label {
    font-weight: normal;
}

.footer-newsletter-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px; 
  }
  
  .footer-newsletter-checkbox-item {
    display: flex;
    align-items: center;
  }
  
  .footer-newsletter-recaptcha {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .footer-newsletter-thankyou {
    text-align: center;
    margin-top: 20px;
  }

  .footer-newsletter-modal-content h2 {
    color: #133c7a;
    margin-bottom: 20px;
    margin-top: 0px;
    font-size: 22px;
    font-weight: bold;
}

.newsletter-footer-desc {
    font-size: 11px;
    margin-bottom: 1em;
}

.newsletter-footer-desc a:hover,a:active {
    color: #f3ac3a;
    text-decoration: underline;
}

.newsletter-footer-desc a {
    text-decoration: underline;
    cursor: pointer;
}

.footer-recaptcha {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 74px;
    width: 206px;
}

@media only screen and (max-width: 500px) {
    #footer {
        background: #0055a5 none repeat scroll 0 0 !important;
    }
}

@media only screen and (max-width: 950px) {
    .footer .blogo {
        display: none !important;
    }
	#footer {
	margin-bottom: 0px;
	} 
}


#sticky {
    padding-top: 12px !important;
    padding-bottom: 32px !important;
}

#sticky {
    width: 100%;
    height: 15px;
    padding-top: 19px;
    padding-bottom: 15px;
    background: #555;
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
}

#sticky .cta #cta_link {
  padding:6px 20px;
  background:white;
  font-size:16px;
  border:none;
  border-radius:8px;
  cursor:pointer;
  position:relative;
}
#sticky .cta button:focus {
  outline:none;
}
#sticky .cta button:active {
  top:4px
}

.sticky-apply-now-button a {
    background-color: rgb(255, 0, 0);
    color: white;
    font-weight: bold;
    padding: 6px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
}

.sticky-apply-now-button a:hover {
    text-decoration: underline !important;
}

@media only screen and (max-width: 950px) {

    .float_left.m {
        float: left;
        text-align: center !important;
        width: 100%;
        margin-bottom: 10px;
    }
	    .float_left.l {
        float: left !important;
        text-align: center !important;
        width: 100% !important;
    }
	    .newsletter_btn_wrapper {
        margin-left: 0 !important;
    }
	    .newsletter_btn {
        width: 80% !important;
    }
	    .float_right.r {
        float: right !important;
        margin-right: 0 !important;
        text-align: center !important;
        width: 100% !important;
    }

    #sticky {
        width: 100%;
        height: 15px;
        padding-top: 19px;
        padding-bottom: 29px!important;
        background: #555;
        color: #fff;
        text-align: center;
        position: fixed;
        bottom: 0;
        left: 0
    }
	}
	
	#sticky .cta #cta_link {
  padding:6px 20px;
  background:white;
  font-size:16px;
  border:none;
  border-radius:8px;
  cursor:pointer;
  position:relative;
}
#sticky .cta button:focus {
  outline:none;
}
#sticky .cta button:active {
  top:4px
}

.float_left.l {
    float: left;
    width: 35%;
}

.float_left.m {
    float: left;
    width: 23%;
}

.newsletter_btn_wrapper {
    margin-left: 29%;
}

.newsletter_btn {
    width: 100%;
    display: block;
    margin: 0;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px !important;
    line-height: 25px;
    text-decoration: none !important;
    border-radius: 10px;
    padding: 8px 10px;
    text-align: center;
    background-color: #0078c1;
    border: 1px solid #0078c1;
    cursor: pointer;
}

.float_right.r {
    float: right;
    width: 25%;
}

@media screen and (max-width: 600px) {
    .float_left.m {
        width: 100%;
    }
  }