
.course-selection {
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 3%;
  clear: both;
}

.toolbar-pf {
  background-color: #fff;
  border-bottom: 1px solid #d1d1d1;
  box-shadow: 0 1px 0 rgba(3, 3, 3, .045);
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.toolbar-pf-results {
  background-color: #fff;
  border-bottom: 1px solid #d1d1d1;
  box-shadow: 0 1px 0 rgba(3, 3, 3, .045);
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}

.toolbar-pf-results h5 {
  font-size: 18px;
  margin: 0;
  padding-top: 13px;
}

.custom-select {
  width: 250px;
  height: 48px;
  border: 1px solid #D0DBEB;
  border-radius: 5px;
  padding: 10px;
  background-color: #ECEDF1;
}


.view-cart-button-smw {
  text-align: center;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  appearance: none;
  outline: 0;
  -webkit-appearance: none;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 35px;
  background-color: #ED202C;
  color: #fff;
  padding: 12px 24px;
  vertical-align: bottom;
}

.please-note {
  grid-column: 1 / -1;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 21px;
  color: #063E7B;
  padding-left: 15px;
}

.toolbar-pf ul li {
  margin-bottom: 15px;
}

.select-classroom {
  width: 100%;
  height: 48px;
  border: 1px solid #D0DBEB;
  border-radius: 5px;
  padding: 10px;
  color: #000;
}

.column1,
.column2,
.column3,
.column4 ul li {
  padding: 0px;
}

.column3 a {
  color: #2c99d2;
  text-decoration: none;
}


/* Styles for images */
.img-ig {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgssl {
  list-style: none;
}

.mobile_secure_image {
  display: none;
}



.course-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.course-card {
  position: relative;
  border: 1px solid #ddd;
  width: 275px;
  height: 440px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  padding-right: 2px;
  padding-left: 2px;
  z-index: 0;
}

span.sta-pay {
  float: right;
  font-weight: 600;
  padding-right: 9px;
}

.sta-spay-label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.0em;
  margin: 0px auto;
  cursor: pointer;
  transition: all 0.25s linear;
}

.two-courses {
  max-width: 100%;
}

.smww-pay-heading {
  text-align: center;
  padding: 10px;
  border-bottom-style: dashed;
  border-bottom-width: thin;
  border-bottom-color: #CBCBCB;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 0px;
    font-weight: 700;
    font-size:14px;
}
.course-card h3 strike {
  margin-left:6%;
}
.toolbar-pf .column1 ul li, .toolbar-pf .column2 ul li, .toolbar-pf .column3 ul li{
  list-style: outside;
}
.helptext {
  font-size: 10px;
}

.small-font {
  font-size: 12px; 
}

.select-classroom {
  margin-top: 6px;
}

.payment-option {
  display: inline-flex;
  align-items: center;
  margin-right: 1px;
  padding: 18px 10px 18px 0px;
  border-bottom: 1px solid #D0DBEB;
}

.check {
  display: block;
  position: absolute;
  border: 1px solid #AAAAAA;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  top: 18px;
  left: 0px;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}

.payment-option input[type="radio"] {
  width: 18px;
  height: 18px;
}

.payment-option .price {
  font-weight: bold;
  float: right;
}

.add-to-cart {
  background-color: #f04;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.smww-pay-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  appearance: none;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 35px;
  background-color: #ED202C;
  color: #fff;
  padding: 7px 24px;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.25);
  width: 132px;
  margin: 0 auto;
}

.des {
  color: #063E7B;
  padding-left: 15px;
  padding-top: 10px;
}







/* Success message styling */
.dim-background {
  position: relative;
}

.dim-background::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}


.success-message {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 80%;
  max-width: 950px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
 
  border-radius: 15px;
}
.success-message-header {
    color: #000;
    border-bottom: 1px solid;
    padding: 14px 16px;
}
.success-message-header .close { 
  color: #000;
    float: right;
    font-size: 28px;
    font-weight: 700;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity = 20);
    opacity: .2;
    margin-top: -6px;
}
.success-message-content {
  padding: 14px 16px;
}
.success-message p {
  font-size: 18px;
  margin-bottom: 15px;
  color: #234600;
}

.message-content {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-top:10px;
  background-color: #f8fff0 !important;
  border: 1px solid #be7;
  
}

.message-icon {
  font-size: 24px;
  color: green;
  margin-right: 10px;
  margin-left: 10px;
}

.message-text p {
  font-size: 14px;
}

.success-buttons {
  justify-content: space-between;
  margin-bottom:20px;
}

.success-buttons .view-cart-button,
.success-buttons .checkout-button,
.success-buttons .close-button {
  background-color: #00bfff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 35px;
  cursor: pointer;
  font-weight:700;
}

.success-buttons .view-cart-button:hover,
.success-buttons .checkout-button:hover,
.success-buttons .close-button:hover {
  background-color: #009acd;
}

.show_error {
  border-color: red;
}

@media only screen and (max-width: 767px) {
	.desktop_secure_image {
		display:none;
		}
		.mobile_secure_image {
		display:block;
		text-align:center;
		padding-bottom:15px;
		}
		.smw_margin_2perc {
			display:block;
			text-align:center;
			padding-top: 3%;
			clear: both;
		}
		.pay_view_cart {
			display:block;
			text-align:center;
			margin-bottom:15px;
		}

    .img-ig {
      display: none;
    }

    .toolbar-pf {
      grid-template-columns: 1fr;
    }
  
    .column1,
    .column2,
    .column3,
    .column4 {
      padding: 0;
    }
  
    .mobile_secure_image {
      display: block;
      text-align: center;
      padding-top: 15px;
    }    

      .toolbar-pf-results {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
  
    .success-message {
      width: 100%;
      max-width: max-content;
  }
  .course-card h3 {
    font-size:15px;
  }
  .course-card h3 strike {
    margin-left:6%;
  }
  .custom-select {
    font-size:14px;
    color:#333;
  }
  .select-classroom {
    font-size:14px;
  }
  .success-buttons .view-cart-button,
.success-buttons .checkout-button,
.success-buttons .close-button {
font-size:12px;
}
	}


@media (min-width: 1024px) {
  .course-grid {
    justify-content: unset;
  }

  .course-card {
    flex: 0 1 calc(26% - 20px);
  }
  .success-message {
    width: 100%;
}
}

@media (max-width: 1024px) and (min-width: 481px) {
  .course-card {
    flex: 0 1 calc(50% - 20px);
  }
  .success-message {
    width: 100%;
    max-width: max-content;
}
}

@media (max-width: 500px) {
  .course-grid {
    display:block;
    padding-right:15px;
    padding-left:15px;
  }
  .course-card {
    width:100%;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 5%;
  }
  .course-card .smww-pay-button {
    width:55% !important;
  }
  .success-message {
    width: 100%;
    max-width: max-content;
}

.course-selection {
  padding: 2%;
}

}
