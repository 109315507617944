.store-container {
  max-width: 1170px;
  margin: 0 auto;
  margin-bottom: 4%;
  padding: 10px;
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
    row-gap: 10px;
  justify-content: normal;
}
.search-container input[type=text] {
  padding: 10px;
  width: 80%;
  border-radius:0px;
  margin-left: auto;
  
}
.search-container {
  display:flex;
  /* margin-top: 30px; */
  margin-left: auto;
  width:40%;
}




.search-container button {
  width: 12%;
  padding: 10px;
  background: #fff;
  color: #000;
  font-size: 17px;
  border: 1px solid #D0DBEB;
  cursor: pointer;
  margin-left: auto;
  height: 40px;
}

.search-container button:hover {
  background: #0b7dda;
}

.search-container::after {
  content: "";
  clear: both;
  display: table;
}
#clear {
  margin: auto -23px;
}
.apparel-banner {
  max-width: 100%;
  margin-top: 25px;
  margin-bottom: 35px;
}

.filters-container {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 30px;
  max-width: 100%;
  box-shadow: 0 1px 0 rgba(3, 3, 3, .045);
  background: #fff;
  margin-bottom: 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}

.filters-container span {
  font-size: 16px;
  margin-right: 10px;
}
.sort-and-category{
  display:flex;
  flex-direction: row;
}
.item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 550px) {
  
  .item-wrapper{
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  
  .sort-and-category-item {
    margin-top: 8px;
    text-align: right;
  }
}


.store-sort {
  width: 190px;
  height: 40px;
  border: 1px solid #D0DBEB;
  border-radius: 5px;
  padding: 10px;
  background-color: #ECEDF1;
  margin-right: 20px;
}
@media (max-width: 550px) {
  
  .store-sort{
    width: 100%;
    
  }
}

.store-search {
  width: 300px;
  height: 40px;
  border: 1px solid #D0DBEB;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
}

.item-card {
  text-align: center;
  width: 22%;
  box-sizing: border-box;
}

.item-card img {
  width: 275px;
  height: 275px;
  max-width: 100%;
  border-bottom: 1px solid #DEE4EE;
}

.item-card h2 {
  color: #003c7d;
  padding-top: 10px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;  
  margin: 10px 0;
}

.item-card p {
  color: red;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  border: 1px solid #ddd;
  background-color: #fff;
  color: #2C99D2;
  padding: 7px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 15px;
}

.pagination button.active {
  background-color: unset;
  color: #333;
  border: none;
  font-weight: bold;
}

.pagination button:hover {
  background-color: #d8d6d6;
}

.store-card-pf {
  padding-left: 10px;
  padding-right: 10px;
  height: 275px;
  text-align: center;
  width: 275px;
}
.store-card-pf {
  background: #fff;
  border: 1px solid #DEE4EE;
}

/* Responsive Design */

@media (max-width: 1200px) {
  .item-card {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .item-card {
    width: 45%;
  }
}

@media (max-width: 500px) {
  .item-card {
    width: 100%; 
  }

  .filters-container {
    padding: 10px;
    flex-wrap: unset;
  }

  @media screen and (max-width: 1000px) {
    .filters-container {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 600px) {
    .filters-container {
      flex-direction: column;
    }
    .sort-and-category {
      flex-direction: column;
    }
    .search-container {
      /* margin-top: 15px; */
      margin-top: 25px;

      width: 100%;
    }
    .search-container input[type=text] {
      padding: 10px;
      width: 100%;
      border-radius:4px;
      margin-left: auto;
      
    }
  }

  .filters-container-item {
    flex:1;
    width: 100%;
  }


  .store-search {
    margin-left: 0px;
    width: auto;
    padding: 5px;
  }
  .store-card-pf {
    padding-left: 10px;
    padding-right: 10px;
    height: 180px;
    text-align: center;
    width: 100%;
}

.item-card img {
  width: 156px;
  height: 156px;
  max-width: 100%;
 border-bottom: none; 
}
}
