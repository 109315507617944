body {
    
    font-size: 14px;
    font-family: sans-serif, arial, verdana;
    color: #000;
}

.request-form-container {
    width: 100%;
    max-width: 1170px;
    height: auto;
    margin: 0 auto;
    min-height: 450px;
    margin-bottom: 20px;
}

.request-info {
    margin-left: 1%;
    max-width: 865px;
    width: 73%;
}

.req-paragraph a {
    color: #2c99d2;
    text-decoration: none;
}

.req-paragraph p {
    line-height: 1.375;
    margin-bottom: 1em;
}


.form-required {
    color: #f00;
}

.info-form input, .info-form textarea {
    border: 1px solid #ccc;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-box-shadow: 2px 2px 3px #666;
    -webkit-box-shadow: 2px 2px 3px #666;
    box-shadow: 2px 2px 3px #666;
    font-size: 15px;
    padding: 4px 7px;
    outline: 0;
    margin-bottom: 5px;
    width: 40%;
}

.info-form textarea {
    height: 136px;
}

.info-form label {
    margin-bottom: 5px;
}

.info-fields {
    margin-top: 14px;
    margin-bottom: 14px;
}

.description {
    font-size: .85em;
}

.info-submit, .info-submit:visited {
    background: #222 url(https://www.sportsmanagementworldwide.com/sites/all/themes/smww/css/overlay.png) repeat-x;
    display: inline-block;
    padding: 5px 10px 6px;
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    position: relative;
    cursor: pointer;
    border: 1px;
}

.info-submit, .info-submit:visited {
    background-color: #004b85;
}

.info-submit, .info-submit:visited, .medium.info-submit, .medium.info-submit:visited {
    font-size: 15px;
    line-height: 1;
}
.info-submit {
    margin-top: 5px;
    margin-bottom: 5px;
    font-style: normal;
}

@media only screen and (max-width: 850px) {
    body {
       
        color: #333;
        font-family: sans-serif, arial, verdana;
        font-size: 16px;
    }

    .info-form input, .info-form textarea {
        width: 100%;
    }

    .request-form-container {
        width: 96%;
    }    

    .request-info {
        width: auto;
        margin-top: 5%;
    }

}