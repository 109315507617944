.forget-page-container {
    max-width: 1170px;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 10%;
   
  }
  .forget-page-content {
    max-width: 865px;
    width: 73%;
  }
  .forget-page-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .forget-page-form {
    display: flex;
    flex-direction: column;
  }
  
  .forget-page-form-group {
    margin-bottom: 15px;
  }
  
  .forget-page-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .forget-page-input {
    width: 465px;
    border: 1px solid #000;
}
.forget-page-container .error {
  color:red;
  display:block;
}
  

  .forget-page-submit-btn {
    background: #222 url('../../../public/images/overlay.png') repeat-x;
    background-color: #004b85;
    display: inline-block;
    padding: 5px 10px 6px;
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    position: relative;
    cursor: pointer;
    border: 1px;
    width: 165px;
    font-size: 15px;
}

  .forget-page-submit-btn:hover {
    background-color: #0056b3;
  }
  
  .forget-page-message {
    margin-top: 20px;
    padding: 10px;
  }
  
  .forget-page-warning {
    background-color: #fffce5;
    border: 1px solid #ed5;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 855px;
}

.forget-page-warning img {
  width: 24px;
  height: 24px;
}

.forget-page-warning span {
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
  
  .forget-page-error {
    background-color: #fef5f1;
    border: 1px solid #ed541d;
    color: #8c2e0b;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 855px;
  }

  .forget-page-error img{
    width: 24px;
    height: 24px;
  }

  .forget-page-error p{
    margin: 0;
    font-size: 15px;
}


  .forget-page-required {
    color: red;
  }
  

  @media (max-width: 500px) {
    .forget-page-container {
      padding: 10px;
      background-color: #fff;
    }

    .forget-page-error {
      width: auto;
  }
  
  .forget-page-warning {
    width: auto;
  }

  .forget-page-input {
    width: 350px;
}

  }
  