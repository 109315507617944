.misc_charge .quick-links-reg-button {
    margin: 0 auto;
    display: block;
}
.select-lineitems {
    width:100%;
}
.misc_charge .row {
    display: flex;
}
.misc_charge .col-md-1 {
    width:15%;
    
}
.misc_charge .col-md-2 {
    width:35%;
    
}
.misc_charge .col-md-3 {
    width:25%;
    
}
/* The Modal (background) */
.misc_charge .modal {
   
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  /* Modal Content */
  .misc_charge .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: .4s;
    animation-name: animatetop;
    animation-duration: .4s;
    width: 40%;
    border-radius: 15px;
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .misc_charge .close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: 700;
  }
  
  .misc_charge .close:hover,
  .misc_charge .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .misc_charge .modal-header {
    padding: 1px 16px;
    color: #000;
    border-bottom: 1px solid;
  }
  
  .misc_charge .modal-body {padding: 2px 16px;padding-top:3%;
    padding-bottom: 20px;}
  
  .misc_charge .modal-footer {
    padding: 2px 16px;
    
    color: #000;
  }
  .add_new_link {
    color: #0071b3 !important;
  }