.checkout-container {
    width: 100%;
    margin: 0 auto;
    max-width: 1225px;
    height: auto;
    clear: both;
  }
  
  .checkout-page {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .checkout-page-order-summary {
    width: 390px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .shipping-info {
    width: 780px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .ch-inline-inputs {
    display: flex;
    justify-content: space-between;
  }
  
  .ch-inline-inputs input {
    width: 49%;
  }
  
  .shipping-info h2,
  .checkout-page-order-summary h2 {
    margin-bottom: 20px;
    color: #004b8d;
  }
  
  .ch-order-details,
  .shipping-info form,
  .checkout-page-order-summary {
    display: flex;
    flex-direction: column;
  }

  .checkout-page-order-summary {
    height: fit-content;
  }
  
  .shipping-info input,
  .shipping-info select,
  .checkout-page-order-summary input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
 
  .next-button1 {
    background-color: #ED202C;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 15px;
}
  
  .next-button2 {
    background-color: #ED202C;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
  }

  .get-shipping-options {
    float: right;
    width: 180px;
    background-color: #ED202C;
    margin-bottom: 21px;
    line-height: 0;
    padding: 17px 17px;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}




  .coupon-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .coupon-container-dotted {
    border: 2px solid #DDDDDD;
    border-style: dashed;
    padding: 15px 5px 5px 5px;
    margin-top: 15px;
}

  .coupon-container input {
    width: 100%;
    padding-right: 80px; 
  }
  
  .coupon-container .apply-coupon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-65%);
    background-color: #063E7B;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    height: 77%;
}
  
.coupon-remove {
  color: #2c99d2;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.loader {
  width: 16px;
  height: 16px;
}


  .checkout-page-order-summary .ch-order-details,
  .checkout-page-order-summary .ch-order-details .order-shipping,
  .checkout-page-order-summary .ch-order-details .ch-order-total
   {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .order-items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: 700;
    color: #003c7d;
    font-size: 15px;
  }

 .ch-total {
    font-size: 21px;
    font-weight: 700;
  }

  .ch-total-amount {
  font-size: 21px;
    color: #000;
  }

.ch-entering {
  font-size: 10px;
  color: #333;
}

.ch-entering a {
  color: #2c99d2;
  text-decoration: none;
  font-size: 10px;
}

.product-details {
  display: flex;
  flex-direction: column;
  color: #707070;
  font-weight: 700;
}
.details-row {
  display: flex;
  justify-content: space-between;
}
.coupon-details {
  display: flex;
  flex-direction: row;
  color: #707070;
  font-weight: 700;
  justify-content: space-between;
}
.product-details h3 {
    margin-bottom: 10px;
  }
  
  .order-note p {
    margin-bottom: 10px;
  }
  
  .shipping-options-box {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
  }
  
  .shipping-options-box h3 {
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #003c7d;
  }
  
.checkout-hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}


.checkout-title {
  font-size: 18px;
  color: #333;
  font-weight: 500;
  margin-top: 0px;
}

.ch-login-button {
  float: right;
  background-color: #ED202C;
  color: #fff;
  border: none;
  border-radius: 5px;
    line-height: 0;
    width: 100%;
    height: 36px;
}


  .checkout-page-login {
    margin-top: 10px;
    margin-bottom: 25px;
    font-weight: 500;
    line-height: 1.1;
    font-size: 18px;
    color: #333;
  }
  .shipping-options-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }


  
  .shipping-options-table th,
  .shipping-options-table td {
    border: 0px solid #ddd;
    padding: 10px;
    text-align: left;
    border-top: 1px solid #ddd;
  }
  .ch-order-details .product-details {
    display:block;
    font-size:14px;
  }
  .order-note span {
    font-size:14px;
  }
  .shipping-options-box p {
    color:#0F48A2;
    font-size:16px;
    font-weight: normal;
    line-height: 16px;
  }
  .shipping-options-table thead>tr>th {
    padding: 8px;
    font-weight: 700;
    vertical-align: middle;
    color: #0F48A2;
    border: 0px solid #ddd !important;
    border-top: 1px solid #ddd;
  }
  .shipping-options-table thead>tr>th:nth-child(2) {
    width: 35%;
  }
  .shipping-options-table .radio_btn {
    text-align:right;
  }
  .shipping-options-table >tbody>tr>td {
    background-color: #f5f5f5;
  }
  .top-login {
    width:100%;
  }
  .top-login-text {
    width:80%;
    float:left;
    padding-right:2%;
  }
  .top-login-btn {
    width:20%;
    float:left;
  }
  .shipping-option-top-wrapper {
    width:100%;
    display:flex;
  }
  .shipping-option-heading {
    width:76%;
    float:left;
  }
  #shipping-form {
    clear:both;
  }
  /* Media Queries for Mobile and Tablet */
  
  @media (max-width: 1024px) {
    .checkout-page {
      flex-direction: column;
      align-items: center;
    }
  
    .checkout-page-order-summary,
    .shipping-info {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .ch-inline-inputs {
      flex-direction: column;
    }
  
    .ch-inline-inputs input {
      width: 100%;
    }
    .next-button1 {
      margin-bottom: 20px;
    }

    .mob-container-ch {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .checkout-page {
      padding: 10px;
    }
  
    .checkout-page-order-summary,
    .shipping-info {
      padding: 10px;
    }
  
    .next-button1 {
      margin-bottom: 20px;
    }


    .get-shipping-options {
      padding: 18px;
        margin-top: 8px;
        font-size:13px;
        font-weight:700;
    }
  
    .shipping-info input,
    .shipping-info select,
    .checkout-page-order-summary input {
      padding: 8px;
      margin-bottom: 8px;
    }
    .checkout-page-order-summary input, .shipping-info input, .shipping-info select {
      color: #555;
      font-size: 14px;
    }
    .ch-login-button {
    font-weight:700;
    font-size:13px;
    }
    .next-button1, .next-button2 {
      font-weight:700;
      font-size:14px;
    }
  }
  @media (max-width: 600px) {
  .shipping-options-box h3 {
    margin-bottom:0px;
  }
  .shipping-options-box p { 
  clear:both;
  }
  .shipping-option-top-wrapper {
    display:block;
  }
  .shipping-option-top-wrapper {
    width:100%;
    float:none;
  }
  .shipping-options-box {
    overflow: hidden;
  }
  .get-shipping-options {
    width:100%;
    line-height:15px;
    margin-top: 15px;
    padding: 10px 10px;
  }
  .shipping-option-button {
    text-align: right;
    float:right;
    width:40%;
  }
  .shipping-option-heading {
    float: left;
    width: 60%;
  }
  .next-button1, .next-button2 { 
    clear:both;
  }
  }
  @media (max-width: 440px) {
    .get-shipping-options {
   margin-top:9px;
    }
  }