.referral-email-form {
    max-width: 600px;
    margin: 0 auto;
    background: #f1f1f1;
    padding: 20px;
    border: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .referral-email-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .referral-email-form form {
    display: flex;
    flex-direction: column;
  }
  
  .referral-email-form-quill-content {
    margin-bottom: 15px;    
  }

  .referral-email-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .referral-email-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }

  .referral-email-form textarea {
    width: 100%;
    height: 55px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    margin-bottom: 15px;
  }
  
  .referral-email-form .ql-container {
    height: 150px;
  }
  
  .referral-email-form-button {
    width: 98px;
    margin: 0 auto;
    padding: 9px 12px;
    background-color: #FF0000;
    color: #fff;
    border: none;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  

  .refer-email-name-fl input {
    width: 275px;
  }

  .refer-email-name-fl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .ql-editor p {
    margin-bottom:3%;
  }