.size-chart-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111;
}

.size-chart-modal {
  background: white;
  padding: 15px;
  border-radius: 8px;
  max-width: 670px;
  width: 100%;
  padding-top: 0;
  
}

.size-chart{
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}


.sz-hdg {
  font-weight: 700;
}

.size-hdg {
  border-bottom: 2px solid #e5e5e5;
}

.apparel-modal-close {
  background: none;
  border: none;
  cursor: pointer;
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #97999b;
  text-shadow: 0 1px 0 #fff;
}

.size-chart-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.size-chart-table tr:nth-child(even) {
  background: #f2f4f2;
}

.size-chart-table tr {
  border: 1px solid black;
}

.size-chart-table th, .size-chart-table td {
  padding: 8px;
  text-align: center;
}

.size-chart-table th {
  background-color: #f2f2f2;
}
.size-chart-modal table tr:nth-child(even) {
  background: #f2f4f2;
}
.size-chart-modal table tr:nth-child(odd) {
  background: #FFF;
}