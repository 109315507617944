.thankyou-container {
  background-color: #E1E7EE;
  padding-top: 5%;
  padding-bottom: 4%;
}

.thank-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 1170px;
}

.thankyou-text {
  display: block;
  text-align: center;
  font-size: 35px;
  color: #000;
  font-weight: 900;
  margin-bottom: 20px;
}

.info-link a {
  color: #2C99D2;
  text-decoration: none;
}

.thankyou-image {
  width: 50%;
  height: auto;
}

.thankyou-form {
  width: 45%;
  padding: 20px;
  border-radius: 10px;
}

.thankyou-container .form-group {
  margin-bottom: 20px;
}

.thankyou-container .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
background-color: #e4e4e4;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 27px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
  border-radius: 20px;
  width: 81px;
  cursor: pointer;
}


.thankyou-container .form-group input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}


.thankyou-container .form-group input:checked + label {
  background-color: #a5dc86;
  box-shadow: none;
}


.thankyou-container .form-group .radio-label.active {
  background-color: #a5dc86;
  color: #fff;
}

.yes-no-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.yes-button, .no-button {
  padding: 8px 27px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 800;
}

.yes-button.active, .no-button.active {
  background-color: #a5dc86;
}

.yes-button:active, .no-button:active {
  background-color: #a5dc86;
}

.yes-button {
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 27px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.no-button {
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.th-dropdown-1 {
display: inline-flex;
}

.th-dropdown-1 select {
  width: 222px;
  height: 48px;
  border: 1px solid #D0DBEB;
  border-radius: 5px;
  padding: 10px;
  background-color: #ECEDF1;
}


.th-dropdown-1 label {
  width: auto;
  margin-top: 17px;
  padding-right: 10px;
  font-weight: 500;
}

.th-dropdown-2 {
  width: 70%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.th-dropdown-3 {
  width: 70%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ECEDF1;
}

.info-link {
  margin-top: 10px;
  font-size: 14px;
}


.thankyou-form h2 {
  font-size: 16px;
  margin-bottom: 8px;
  color: #393F67;
  line-height: 29px;
}

.submit-button {
  text-align: center;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  appearance: none;
  outline: 0;
  -webkit-appearance: none;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 35px;
  background-color: #ED202C;
  color: #fff;
  padding: 7px 24px;
  vertical-align: bottom;
}

.submit-button:hover, .submit-button:focus {
  background-color: #A3042D;
}
.thank-action_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.thankyou-buttons {
  width: 100%;
  background-color: #ED202C;
  margin-bottom: 21px;
  line-height: 0;
  padding: 18px 65px;
  margin-left: 14px;
  color: #fff !important;
  border-color: #d43f3a;
  border-radius: 5px;
  box-shadow: none;
  font-size: 18px;
  border: none;
  cursor: pointer;
}
.course-thankpage-image {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.course-thank-container {
  display: block;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 1170px;
}
@media only screen and (max-width: 500px) {
  .thank-container {
    flex-direction: column;
    align-items: center;
  }

  .thankyou-image {
    width: 80%;
    margin-bottom: 20px;
  }

  .thankyou-form {
    width: 80%;
  }

  .th-dropdown-1 {
   width: 100%;
    }
    .th-dropdown-1 label {
      width: auto;
      margin-top: 2px;
      padding-right: 10px;
  }

  
    .th-dropdown-2 {
      width: 100%;
       }
       
       
  .th-dropdown-3 {
    width: 100%;
     }
     .thank-action_buttons {
      flex-direction: column;   
      width: 100%;  
    }
  
    .thankyou-buttons {
      width: 285px;
      margin-left: auto;
      margin-right: auto;
    }

}
