body {
    font-family: sans-serif, arial, verdana;
    background: #f6f5f5 !important;
}
p {
    line-height: 1.375;
}

.call b {
    display: none;
}
.header-hello-text a:hover,a:active {
    color: #f3ac3a;
    text-decoration: underline;
    font-size: 14px;
}

.user-impersoanting {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #fffce5;
    color: #840;
    border-color: #ed5;
    border: 1px solid;
    padding: 10px;
    text-align: center;
    max-width: 1180px;
    margin: 0 auto;
    margin-top: 1%;
    margin-bottom:1%;
}

.user-impersoanting a {
    color: #0071B3;
    text-decoration: underline;
    cursor: pointer;
}


.fa {
    display: inline-block !important;
    font: normal normal normal 14px / 1 FontAwesome !important;
    font-size: inherit !important;
    text-rendering: auto !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

.sticky-header-button {
    background-color: red;
    color: #fff !important;
    padding: 1px 20px 5px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
}
.call em {
    font-style: normal;
    color: #003c7d;
}
a:link, a:visited {
    color: #000;
    text-decoration: none;
    font-size: 14px;
}
.cart-icon {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 24px;
    background-image: url(../images/header/cart-smww.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
}
#totalItems {
    position: absolute;
    top: -7px;
    right: 0;
    height: 20px;
    width: 20px;
    line-height: 20px;
    background-color: #063e7b;
    color: #fff;
    font-weight: bolder;
    text-align: center;
    border-radius: 50%;
    transform: scale(1);
    transition: transform 0.2s 0s;
    font-size: 14px;
}
.share span.a {
    background-position: 0 0 
}
.share span.b {
    background-position: 0 -30px 
}
.share span.c {
    background-position: 0 -60px 
}
.share span.d {
    background-position: 0 -90px 
}
.share span.e {
    background-position: 0 -120px 
}
.share span {
    display: inline-block;
    width: 29px;
    height: 25px;
    cursor: pointer;
    background: url("../images/header/social_media_new_icons.png") no-repeat;
}
.logo a {
    width: 100%;
    max-width: 679px;
    height: 164px;
    display: block;
    line-height: 164px;
    background: url("../images/header/new_logo.png") no-repeat;
}
#header {
    width: 100%;
    margin: 0 auto;
    background: #d8d9da;
    position: relative;
}
.navbg {
    width: 100%;
    height: 54px;
    position: absolute;
    bottom: 0;
    background: #003c7d;
}
.header {
    height: 159px;
    margin: 0 auto;
    max-width: 1170px;
    position: relative;
    width: 100%;
}
.mobile {
    display: none;
}
.logo {
    float: left;
    max-width: 164px;
    width: 21%;
    height: 164px;
    margin-top: 12px;
    margin-right: 45px;
}
.logot {
    max-width: 471px;
    width: 46%;
    height: 54px;
    float: left;
    margin-top: 23px;
    line-height: 0;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    margin-bottom: 25px;
}
.ruby-menu-wrapper {
    z-index:2;
}
.toprihht {
    width: 32%;
    float: right;
    height: auto;
    text-align: right;
    margin-top: 20px;
}
.adv_certificate_menu {
    background: #FAC686;
    padding: 20px;
    border-radius: 8px;
    margin-left: -15px !important;
    margin-right: -15px;
}

.stm-sta-style .adv_certificate_menu .cool-link::after {
    background: #2C99D2;
}
@media (min-width: 851px) {
    .nav {
        margin-right: -50px;
   }
}
@media (min-width: 501px) {
    .share {
        display: flex;
        justify-content: space-between;
   }
}
.nav {
    float: left;
    height: 54px;
    position: relative;
    width: auto;
}
.mobile_call {
    display: none;
}
.call {
    color: #000;
    display: block;
    float: left;
    height: auto;
    line-height: 18px;
    position: relative;
    width: 50%;
    font-size: 14px;
}
.mobile_phone_num {
    display: none;
}
.share {
    float: right;
    height: 35px;
    position: relative;
    width: 50%;
    max-width: 170px;
}
div.ruby-wrapper {
    position: relative;
    width: 800px;
    height: 0;
    margin: 0 auto;
    background: #1b367d;
}
div.ruby-wrapper, div.ruby-wrapper.ruby-vertical {
    background: #f9f9f9;
    color: #222;
}
div.ruby-wrapper>ul {
    position: relative;
   
}
div.ruby-wrapper ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
ul.ruby-menu>li {
    float: left;
    font-size: 14px;
}
ul.ruby-menu>li>a {
    display: block;
    line-height: 47px;
    padding: 0 26px 0 15px;
    color: #efefef;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    margin-top: 6px;
    border-radius: 7px 7px 0 0;
}
span.ruby-dropdown-toggle {
    transition: transform .3s ease 
}
span.ruby-dropdown-toggle:after {
    transition: transform .3s ease 
}
/* Deskrtop Menu CSS (When hovering on menu) */
@media(min-width: 767px) {
    ul.ruby-menu>li>a:after {
        transition: all .3s ease 
   }
    ul.ruby-menu>li>ul>li>a:after,ul.ruby-menu>li>ul>li>ul>li>a:after,ul.ruby-menu>li>ul>li>ul>li>ul>li>a:after {
        transition: all .3s ease 
   }
    ul.ruby-menu>li>div,ul.ruby-menu>li>ul {
        transform: translateY(25px);
        transition: all .3s ease 
   }
    ul.ruby-menu>li:hover>div,ul.ruby-menu>li:hover>ul {
        transform: translateY(0) 
   }
    ul.ruby-menu.ruby-vertical>li>div,ul.ruby-menu.ruby-vertical>li>ul {
        transform: translateX(25px);
        transition: all .3s ease 
   }
    ul.ruby-menu.ruby-vertical>li:hover>div,ul.ruby-menu.ruby-vertical>li:hover>ul {
        transform: translateX(0) 
   }
    ul.ruby-menu>li>ul>li>ul,ul.ruby-menu>li>ul>li>ul>li>ul,ul.ruby-menu>li>ul>li>ul>li>ul>li>ul {
        transform: translateX(25px);
        transition: all .3s ease 
   }
    ul.ruby-menu>li>ul>li:hover>ul,ul.ruby-menu>li>ul>li>ul>li:hover>ul,ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>ul {
        transform: translateX(0) 
   }
    ul.ruby-menu>li>ul>li.ruby-open-to-left>ul,ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left>ul,ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left>ul {
        transform: translateX(-25px);
        transition: all .3s ease 
   }
    ul.ruby-menu>li>ul>li.ruby-open-to-left:hover>ul,ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left:hover>ul,ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left:hover>ul {
        transform: translateX(0) 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        transform: translateY(-50px);
        transition: all .5s ease 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog:hover>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>div,ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>div {
        transform: translateY(0) 
   }
    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        transform: translateX(50px);
        transition: all .5s ease 
   }
    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog:hover>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>div,ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>div {
        transform: translateX(0) 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div {
        transform: translateY(-50px);
        transition: all .5s ease 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop:hover>div>ul>li.ruby-active-menu-item>div,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>div {
        transform: translateY(0) 
   }
    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-shop>div>ul>li>div {
        transform: translateX(50px);
        transition: all .5s ease 
   }
    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-shop:hover>div>ul>li.ruby-active-menu-item>div,ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-shop>div>ul>li:hover>div {
        transform: translateX(0) 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:after,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before {
        -webkit-transition: all .4s ease;
        transition: all .4s ease 
   }
}
*,:after,:before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box 
}
div.ruby-wrapper {
    position: relative;
    width: 800px;
    height: 0;
    margin: 0 auto;
    background: #1b367d 
}
div.ruby-wrapper ul {
    margin: 0;
    padding: 0;
    list-style: none 
}
div.ruby-wrapper>ul {
    position: relative 
}
ul.ruby-menu>li {
    float: left;
    font-size: 14px 
}
ul.ruby-menu>li.ruby-menu-right {
    float: right 
}
ul.ruby-menu>li>a {
    display: block;
    line-height: 47px;
    padding: 0 26px 0 15px;
    color: #efefef;
    text-decoration: none;
    font-family: 'Open Sans',sans-serif;
    margin-top: 6px;
    border-radius: 7px 7px 0 0 
}
ul.ruby-menu>li.no_children>a {
    padding: 0 16px 0 15px 
}
ul.ruby-menu>li>a:only-child {
    padding: 0 14px 
}
ul.ruby-menu>li.ruby-active-menu-item:hover>a,ul.ruby-menu>li.ruby-active-menu-item>a:hover,ul.ruby-menu>li:hover>a,ul.ruby-menu>li>a:hover {
    background: #fff;
    color: #1b367d 
}
ul.ruby-menu>li:hover>a:only-child:after,ul.ruby-menu>li>a:only-child:after {
    content: '' 
}
ul.ruby-menu>li.ruby-active-menu-item>a {
    background: #fff 
}
ul.ruby-menu>li>a .fa {
    position: relative;
    width: 24px 
}
ul.ruby-menu>li>a>img {
    max-width: 16px;
    display: block 
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers>li>a {
    border: none;
    border-top: 1px solid #111;
    border-bottom: 1px solid #666 
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers>li:first-child>a {
    border-top: none 
}
ul.ruby-menu.ruby-menu-dividers>li:hover>a,ul.ruby-menu.ruby-menu-dividers>li>a:hover {
    border-right: 1px solid #19ad86 
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers>li:hover>a,ul.ruby-menu.ruby-vertical.ruby-menu-dividers>li>a:hover {
    border-bottom: 1px solid #19ad86 
}
ul.ruby-menu.ruby-menu-dividers>li.ruby-active-menu-item>a {
    border-right: 1px solid #17a47d 
}
ul.ruby-menu.ruby-vertical.ruby-menu-dividers>li.ruby-active-menu-item>a {
    border-bottom: 1px solid #17a47d 
}
ul.ruby-menu>li>ul,ul.ruby-menu>li>ul>li>ul,ul.ruby-menu>li>ul>li>ul>li>ul {
    position: absolute;
    -webkit-box-shadow: 0 8px 24px -5px #ccc;
    -moz-box-shadow: 0 8px 24px -5px #ccc;
    box-shadow: 0 8px 24px -5px #ccc;
    border-top: 4px solid #f3ac3a 
}
ul.ruby-menu>li>ul>li,ul.ruby-menu>li>ul>li>ul>li {
    position: relative 
}
ul.ruby-menu>li>ul>li>a,ul.ruby-menu>li>ul>li>ul>li>a,ul.ruby-menu>li>ul>li>ul>li>ul>li>a {
    display: block;
    width: 250px;
    line-height: 21px;
    padding: 0 35px 0 25px;
    background: #fff;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: left;
}
ul.ruby-menu>li>ul>li:hover>a,ul.ruby-menu>li>ul>li>a:hover,ul.ruby-menu>li>ul>li>ul>li:hover>a,ul.ruby-menu>li>ul>li>ul>li>a:hover,ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>a,ul.ruby-menu>li>ul>li>ul>li>ul>li>a:hover {
    background: #efefef 
}
ul.ruby-menu>li>ul>li>a:only-child:after,ul.ruby-menu>li>ul>li>ul>li>a:only-child:after,ul.ruby-menu>li>ul>li>ul>li>ul>li>a:only-child:after {
    content: "" 
}
ul.ruby-menu>li>ul>li>a .fa,ul.ruby-menu>li>ul>li>ul>li>a .fa,ul.ruby-menu>li>ul>li>ul>li>ul>li>a .fa {
    position: relative;
    width: 24px 
}
ul.ruby-menu>li.ruby-menu-right>ul {
    right: 0 
}
ul.ruby-menu>li>ul>li>ul,ul.ruby-menu>li>ul>li>ul>li>ul {
    left: 250px;
    top: -4px;
    z-index: 100 
}
ul.ruby-menu>li>ul>li.ruby-open-to-left>ul,ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left>ul {
    left: auto;
    right: 170px 
}
ul.ruby-menu>li.ruby-menu-mega-blog>div,ul.ruby-menu>li.ruby-menu-mega-shop>div,ul.ruby-menu>li.ruby-menu-mega>div {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50px;
    background: #fff;
    border-top: 4px solid #f3ac3a;
    overflow: hidden;
    -webkit-box-shadow: 0 8px 24px -5px #ccc;
    -moz-box-shadow: 0 8px 24px -5px #ccc;
    box-shadow: 0 8px 24px -5px #ccc 
}
ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav {
    position: relative;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    padding-left: 25px;
    padding-right: 16px;
    padding-top: 15px;
    border-radius: 10px;
    text-align: left;
}
ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li {
    font-size: 14px 
}
ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a {
    display: block;
    line-height: 40px;
    padding: 0 15px;
    color: #666;
    text-decoration: none 
}
ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>a {
    background: #1b367d;
    color: #fff;
    border-radius: 5px 
}
ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
    position: absolute;
    width: 600px;
    height: auto;
    min-height: 100%;
    left: 200px;
    top: 0;
    background: #fff 
}
ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>a {
    background: #1b367d;
    color: #fff;
    border-radius: 5px 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div {
    text-align: center;
    z-index: 999999 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul {
    width: 100%;
    background: #efefef;
    margin: 0 auto;
    padding: 0;
    border-bottom: 1px solid #ddd;
    font-size: 0;
    z-index: 100 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li {
    display: inline-block;
    margin: 0 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a {
    display: block;
    padding: 20px 15px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    font-stretch: expanded;
    color: #666 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>a,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover {
    text-decoration: none 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a {
    position: relative;
    text-decoration: none;
    display: inline-block 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div {
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    margin-top: 1px;
    text-align: left;
    background: #fff;
    z-index: -1 
}
div.ruby-grid,div.ruby-grid-lined {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0 
}
div.ruby-grid>div.ruby-row {
    position: relative;
    height: auto 
}
div.ruby-grid>div.ruby-row div.ruby-row [class^=ruby-col] {
    margin-top: 16px 
}
div.ruby-grid>div.ruby-row div.ruby-row:first-of-type [class^=ruby-col] {
    margin-top: 0 
}
div.ruby-grid.ruby-grid-lined>div.ruby-row:after {
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background: -webkit-linear-gradient(left,rgba(204,204,204,0) 0,rgba(204,204,204,.48) 24%,#ccc 50%,rgba(204,204,204,.42) 79%,rgba(204,204,204,0) 100%) 
}
div.ruby-grid.ruby-grid-lined>div.ruby-row:last-child:after {
    height: 0 
}
div.ruby-grid>div.ruby-row::after {
    content: "";
    display: table;
    clear: both 
}
div.ruby-grid>div.ruby-row [class^=ruby-col] {
    position: relative;
    float: left;
    height: auto 
}
div.ruby-grid>div.ruby-row>[class^=ruby-col] {
    padding: 16px 
}
div.ruby-grid>div.ruby-row>[class^=ruby-col] [class^=ruby-col] {
    padding-left: 16px 
}
div.ruby-grid>div.ruby-row>[class^=ruby-col] [class^=ruby-col]:first-of-type {
    padding-left: 0 
}
div.ruby-grid>div.ruby-row div.ruby-col-1 {
    width: 8.33% 
}
div.ruby-grid>div.ruby-row div.ruby-col-2 {
    width: 16.66% 
}
div.ruby-grid>div.ruby-row div.ruby-col-3 {
    width: 25% 
}
div.ruby-grid>div.ruby-row div.ruby-col-4 {
    width: 33.33% 
}
div.ruby-grid>div.ruby-row div.ruby-col-5 {
    width: 41.66% 
}
div.ruby-grid>div.ruby-row div.ruby-col-6 {
    width: 50% 
}
div.ruby-grid>div.ruby-row div.ruby-col-7 {
    width: 58.33% 
}
div.ruby-grid>div.ruby-row div.ruby-col-8 {
    width: 66.66% 
}
div.ruby-grid>div.ruby-row div.ruby-col-9 {
    width: 75% 
}
div.ruby-grid>div.ruby-row div.ruby-col-10 {
    width: 83.33% 
}
div.ruby-grid>div.ruby-row div.ruby-col-11 {
    width: 91.66% 
}
div.ruby-grid>div.ruby-row div.ruby-col-12 {
    width: 100% 
}
div.ruby-grid.ruby-grid-lined>div.ruby-row>div[class^=ruby-col]:after {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    right: 0 
}
div.ruby-grid.ruby-grid-lined>div.ruby-row>div[class^=ruby-col]:last-child:after {
    width: 0 
}
div.ruby-grid img {
    position: relative;
    max-width: 100%;
    height: auto;
    display: block 
}
h3.ruby-list-heading {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div ul,ul.ruby-menu>li.ruby-menu-mega>div ul {
    margin: 0;
    margin-top: 10px 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div ul>li,ul.ruby-menu>li.ruby-menu-mega>div ul li {
    position: relative;
    font-size: 14px;
    line-height: 28px;
    display: block;
    width: 100% 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div ul>li>a,ul.ruby-menu>li.ruby-menu-mega>div ul li>a {
    text-decoration: none;
    color: #555;
    display: inline-block 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div ul>li>a:hover,ul.ruby-menu>li.ruby-menu-mega>div ul li>a:hover {
    color: #222;
    text-decoration: underline 
}
ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div ul>li .fa,ul.ruby-menu>li.ruby-menu-mega>div ul li .fa {
    position: relative;
    width: 24px 
}
ul.ruby-menu>li.ruby-menu-mega>div ul.ruby-list-with-images li {
    height: 50px 
}
ul.ruby-menu>li.ruby-menu-mega>div ul.ruby-list-with-images li span.ruby-list-desc {
    font-size: 11px;
    display: block;
    position: absolute;
    top: 16px;
    left: 72px;
    color: #999 
}
ul.ruby-menu>li.ruby-menu-mega>div ul li>a>img {
    float: left;
    width: 60px;
    margin-right: 12px 
}
span.ruby-c-category,span.ruby-c-date,span.ruby-c-title {
    position: relative;
    display: block 
}
span.ruby-c-category a,span.ruby-c-date a,span.ruby-c-title a {
    text-decoration: none 
}
span.ruby-c-title,span.ruby-c-title a {
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 20px;
    margin-top: -5px;
    color: #222 
}
span.ruby-c-title a:hover {
    color: #444;
    text-decoration: underline 
}
span.ruby-c-category,span.ruby-c-category a {
    font-size: 11px;
    margin: 9px 0;
    color: #444 
}
span.ruby-c-date,span.ruby-c-date a {
    font-size: 11px;
    color: #444 
}
span.ruby-c-category a:hover,span.ruby-c-date a:hover {
    color: #19ad86 
}
div.ruby-c-inline {
    display: block;
    position: relative;
    height: 14px;
    margin: 7px 0 
}
div.ruby-c-inline span.ruby-c-category,div.ruby-c-inline span.ruby-c-date {
    position: absolute;
    display: inline-block 
}
div.ruby-c-inline span.ruby-c-date {
    right: 0 
}
div.ruby-c-inline span.ruby-c-category,div.ruby-c-inline span.ruby-c-category a {
    margin: 0 
}
span.ruby-c-content {
    display: block;
    position: relative;
    top: 7px;
    font-size: 12px;
    line-height: 18px;
    text-align: justify 
}
.ruby-wrapper .visible-xs {
    visibility: hidden;
    position: absolute 
}
.checked-star-menu {
    color: #f3ac3a;
    margin-right: 3px 
}
@media(min-width: 768px) {
    div.ruby-wrapper.ruby-menu-transparent {
        background: 0 0 
   }
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li.ruby-active-menu-item>a {
        background: 0 0 
   }
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li.ruby-menu-social>a {
        border: none 
   }
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu.ruby-menu-dividers>li>a {
        border: none 
   }
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu.ruby-menu-dividers>li:hover>a,div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu.ruby-menu-dividers>li>a:hover,div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li:hover>a,div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li>a:hover {
        background: 0 0;
        color: #e2e2e2 
   }
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li.ruby-menu-social.ruby-menu-search:hover>a,div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li.ruby-menu-social.ruby-menu-search>a:hover {
        background: 0 0 
   }
    div.ruby-wrapper.ruby-menu-full-width {
        width: 100% 
   }
    div.ruby-wrapper.ruby-menu-full-width>ul.ruby-menu {
        width: 980px;
        margin: 0 auto 
   }
    ul.ruby-menu>li>ul,ul.ruby-menu>li>ul>li>ul,ul.ruby-menu>li>ul>li>ul>li>ul {
        visibility: hidden;
        opacity: 0;
        z-index:999;
   }
    ul.ruby-menu>li:hover>ul,ul.ruby-menu>li>ul>li:hover>ul,ul.ruby-menu>li>ul>li>ul>li:hover>ul {
        visibility: visible;
        opacity: 1 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div,ul.ruby-menu>li.ruby-menu-mega-shop>div,ul.ruby-menu>li.ruby-menu-mega>div {
        visibility: hidden;
        opacity: 0 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog:hover>div,ul.ruby-menu>li.ruby-menu-mega-shop:hover>div,ul.ruby-menu>li.ruby-menu-mega:hover>div {
        visibility: visible;
        opacity: 1 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        visibility: hidden;
        opacity: 0 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog:hover>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>div {
        visibility: visible;
        opacity: 1 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>div {
        visibility: visible;
        opacity: 1;
        z-index: 101 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div {
        visibility: hidden;
        opacity: 0 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop:hover>div>ul>li.ruby-active-menu-item>div,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>div {
        visibility: visible;
        opacity: 1 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:after,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before {
        height: 1px;
        position: absolute;
        content: '';
        background-color: #19ad86;
        width: 0 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before {
        top: 10px;
        left: 5% 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:after {
        bottom: 10px;
        right: 5% 
   }
    .snip1217 .current a,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover {
        color: #333 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a:after,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a:before,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>a:after,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>a:before,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover:after,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover:before {
        width: 90% 
   }
    ul.ruby-menu>li>a:after {
        font-family: FontAwesome;
        text-decoration: none;
        content: '\f107';
        position: absolute;
        margin-left: 5px;
        font-size: 10px;
   }
    ul.ruby-menu .no_children>a:after {
        display: none 
   }
    ul.ruby-menu>li:hover>a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg) 
   }
    ul.ruby-menu>li>ul>li>a:after,ul.ruby-menu>li>ul>li>ul>li>a:after,ul.ruby-menu>li>ul>li>ul>li>ul>li>a:after {
        font-family: FontAwesome;
        text-decoration: none;
        font-size: 11px;
        padding: 0;
        content: '\f105';
        position: absolute;
        right: 15px 
   }
    ul.ruby-menu>li>ul>li:hover>a:after,ul.ruby-menu>li>ul>li>ul>li:hover>a:after,ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg) 
   }
    ul.ruby-menu>li>ul>li.ruby-open-to-left>a:after,ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left>a:after,ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left>a:after {
        content: '\f104';
        right: auto;
        left: 10px 
   }
    ul.ruby-menu>li>ul>li.ruby-open-to-left:hover>a:after,ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left:hover>a:after,ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left:hover>a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg) 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>a:after,ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>a:after {
        font-family: FontAwesome;
        text-decoration: none;
        font-size: 16px;
        padding: 0;
        content: '\f105';
        position: absolute;
        right: 28px 
   }
}

.stm-sta-style li {
    margin-bottom: 4px 
}
.stm-sta-style .cool-link {
    display: inline-block;
    color: #000;
    text-decoration: none 
}
.stm-sta-style .cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #f3ac3a;
    transition: width .3s 
}
.stm-sta-style .cool-link:hover::after {
    width: 100% 
}
li.stm-f1>a:after {
    font-family: FontAwesome;
    text-decoration: none;
    font-size: 16px;
    padding: 0;
    content: '\f105';
    position: absolute;
    right: 28px;
    color: #003c7d 
}
.ruby-menu-right1 {
    display: block;
    line-height: 37px;
    color: #efefef;
    text-decoration: none;
    font-family: 'Open Sans',sans-serif;
    background-color: #f10217;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 8px;
    padding: 0 8px 
}
li.ruby-menu-right1 a {
    color: #fff;
    text-decoration: none;
    text-align: center;
    font-size: 18px 
}

.flexnav li ul {
    display: none;
}


@media only screen and (max-width: 500px) {

    #totalItems {
        top: -3px;
    }

    .logo a {
        background: rgba(0, 0, 0, 0) url(../images/header/new_logo.png) no-repeat scroll center center / auto 100% !important;
        display: block !important;
        height: auto !important;
        line-height: 164px !important;
        max-width: 679px !important;
        min-height: 70px !important;
   }

   .mobile .share {
        margin-left: 0%;
        margin-top: 14px;
    }
    .blank20 {
        height: 20px;
    }

    .share {
        display: block !important;
        float: left !important;
        height: 25px !important;
        max-width: 100% !important;
        position: relative !important;
        width: 85% !important;
        margin-top: 4px !important;
        margin-bottom: 4px !important;
        margin-right: 7px !important;
   }

   .nav {
    height: 44px !important;
    background: #19144a none repeat scroll 0 0 !important;
}

.share {
    margin-right: 0px !important;
}

.mobile_call {
    color: #19144a !important;
    float: right !important;
    height: auto !important;
    max-width: 100% !important;
    position: relative !important;
    width: 100% !important;
    display: block !important;
    margin-right: 7px !important;
    line-height: 18.5px !important;
    width: 100% !important;
}

.call {
    margin-top: 0 !important;
    display: none !important;
}

#header {
    float: left !important;
    background: #f1ab46 none repeat scroll 0 0 !important;
}

.header {
    height: auto !important;
}

.mobile {
    display: block !important;
}


.mobile_call {
    color: #19144a !important;
    float: right !important;
    height: auto !important;
    max-width: 100% !important;
    position: relative !important;
    width: 100% !important;
    display: block !important;
    margin-right: 7px !important;
    line-height: 18.5px !important;
    width: 100% !important;
}

.toprihht {
    margin-bottom: 0 !important;
    padding-bottom: 4px !important;
}

.toprihht {
    background: rgba(255, 255, 255, 1) url(../images/header/callbg_background.png) no-repeat scroll left center / auto 100% !important;
    float: right !important;
    margin-bottom: 0 !important;
    padding-top: 5px !important;
    width: 73% !important;
    padding-left: 6% !important;
    margin-right: 0 !important;
    margin-top: 0px !important;
}

.mobile_phone_num a {
    color: #2c99d2 !important;
    font-weight: 700 !important;
}

.mobile_phone_num {
    display: block !important;
    width: 95% !important;
}

.cart-icon {
    top: 6px !important;
    left: -11px !important;
}
.cart-icon {
    position: relative;
    display: inline-block;
    width: 31px;
    height: 30px;
    background-image: url(../images/header/cart-smww.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
}

.nav .share {
    display: block;
    float: right !important;
    height: 25px !important;
    max-width: 100%;
    position: relative;
    width: 98% !important;
    padding-top: 8px;
    margin-right: -2%;
}

.logot {
    display: none !important;
}

.logo {
    margin-right: 0 !important;
    margin-top: 23px !important;
    text-align: center !important;
    width: 27% !important;
    height: 0 !important;
}

.nav {
    height: 44px !important;
    background: #19144a none repeat scroll 0 0 !important;
}


}

@media only screen and (max-width: 850px) {
	
    .navbg {
        display: none 
   }
    .nav {
        background: #003c7d none repeat scroll 0 0;
        float: left;
        height: 54px;
        position: relative;
        width: 100% 
   }
    .logo {
        height: auto;
        max-width: 105px 
   }
    .logo a {
        background: rgba(0,0,0,0) url("../images/header/new_logo.png") no-repeat scroll 0 0 / auto 100%;
        display: block;
        height: auto;
        line-height: 164px;
        max-width: 679px;
        min-height: 92px;
        width: 100% 
   }
    .mobile {
        display: block 
   }
    #cc_form_modal .modal {
        width: 70% 
   }

    .logo {
        height: auto;
        max-width: 105px;
   }
   
       .navbg {
        display: none !important;
   }
   
       .logo {
        color: #fff !important;
        font-size: 20px !important;
        line-height: 5px !important;
        padding: 0 0 0 20px !important;
        text-transform: capitalize !important;
   }
    .header-hello-text {
        white-space: nowrap;
        margin-top: 10px;
   }
   
   
    *,:after,:before {
        box-sizing: border-box;
   }
    ol,ul {
        list-style: none 
   }
    a {
        color: #000;
        text-decoration: none 
   }
    body {
        
        color: #333;
        font-family: sans-serif,arial,verdana;
        font-size: 16px;
        margin: 0;
        padding: 0;
   }
    div .mobile .share {
        display: block !important;
        max-width: 50% !important;
   }
    .sticky-header {
        position: relative !important;
        top: 0;
        width: 100% 
   }
    .menuBtn {
        height: 30px;
        width: 30px;
        position: absolute;
        right: 22px;
        top: 10px;
        z-index: 101 
   }
    .mobile .share {
        position: absolute !important;
        margin-left: 2%;
        margin-top: 18px 
   }
    .mobile .share span {
        vertical-align: middle 
   }
    .mobile .tab_apply_button {
        display: block;
        position: relative;
        top: 15px;
        text-align: center 
   }
    .mobile_apply_button {
        margin-left: 4% !important;
        padding: 3px 7px 3px 7px !important;
        font-size: 14px !important;
   }
    .img_center {
        display: block;
        margin-left: auto;
        margin-right: auto 
   }
    .menuBtn>span {
        background-color: #fff;
        border-radius: 1px;
        height: 2px;
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -1px 0 0 -15px;
        transition: height .1s 
   }
    .menuBtn>span:after,.menuBtn>span:before {
        content: '';
        background-color: #fff;
        border-radius: 1px;
        height: 2px;
        width: 100%;
        position: absolute;
        left: 50%;
        margin-left: -15px;
        transition: all .2s 
   }
    .menuBtn>span:after {
        top: -7px 
   }
    .menuBtn>span:before {
        bottom: -7px 
   }
    .menuBtn.act>span {
        height: 0 
   }
    .menuBtn.act>span:after,.menuBtn.act>span:before {
        background-color: #fff;
        top: 1px 
   }
    .menuBtn.act>span:after {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg) 
   }
    .menuBtn.act>span:before {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg) 
   }
    .mobile_menu_wrapper {
        width: 100% 
   }
    .mainMenu {
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1111;
        height: 600px;
        width: 70%;
        display: table;
        text-align: center;
        opacity: 0;
        transition: all .5s cubic-bezier(.68,-.55,.265,1.55);
        -webkit-transform: scale(0);
        transform: scale(0) 
   }
    .list_submenu {
        text-transform: uppercase;
        line-height: 1.8em;
        padding: 2px 4px 2px 25px!important 
   }
    hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #ccc;
        margin: 0px;
        padding: 0 
   }
   .adv_certificate_mobile_menu {
    background: #FAC686;
            margin: 0 !important;
            /* height: 38px; */
            padding-top: 25px !important;
            padding-bottom: 25px !important;
   }
    #subMenu {
        background-color: #fff;
        position: absolute;
        left: 0;
        top: -30px;
        z-index: 1111;
        height: 750px;
        width: 100%;
        display: table;
        opacity: 0;
        transition: all .5s cubic-bezier(.68,-.55,.265,1.55);
        -webkit-transform: scale(0);
        transform: scale(0) 
   }
    #subMenu1 {
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 0px;
        z-index: 1111;
        height: 750px;
        width: 100%;
        display: table;
        opacity: 0;
        transition: all .5s cubic-bezier(.68,-.55,.265,1.55);
        -webkit-transform: scale(0);
        transform: scale(0) 
   }
    .mainMenu.act {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1) 
   }
    .mainMenu.act ul li {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        margin: 1em 0;
   }
    #subMenu.act,#subMenu1.act {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1) 
   }
    #subMenu.act ul li,#subMenu1.act ul li {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0) 
   }
    #subMenu ul,#subMenu1 ul {
        display: table-cell 
   }
    #subMenu li,#subMenu1 li {
        padding: 11px 18px 11px 18px;
        transition: all .4s 510ms;
        opacity: 1 
   }
    #subMenu li:nth-child(odd),#subMenu1 li:nth-child(odd) {
        -webkit-transform: translateX(30%);
        transform: translateX(30%) 
   }
    #subMenu li:nth-child(even),#subMenu1 li:nth-child(even) {
        -webkit-transform: translateX(-30%);
        transform: translateX(-30%) 
   }
    #subMenu li:last-child,#subMenu1 li:last-child {
        -webkit-transform: none;
        transform: none 
   }
    #subMenu a,#subMenu1 a {
        color: #05407a;
        display: block;
        font-size: 14px 
   }
    .mainMenu ul {
        display: table-cell;
        padding-left: 0px;
        margin: 0 auto;
   }
    .mainMenu ul {
        display: table-cell 
   }
    .mainMenu li {
        padding: 8px 8px;
        transition: all .4s 510ms;
        opacity: 0 
   }
    .mainMenu li:nth-child(odd) {
        -webkit-transform: translateX(30%);
        transform: translateX(30%) 
   }
    .mainMenu li:nth-child(even) {
        -webkit-transform: translateX(-30%);
        transform: translateX(-30%) 
   }
    .mainMenu li:last-child {
        -webkit-transform: none;
        transform: none 
   }
    .mainMenu a {
        color: #05407a;
        display: block;
        font-size: 18px;
        font-weight: 700 
   }
    .mainMenu li:hover {
        background-color: #f1f1f1 
   }
    .mainMenu a.suBtn {
        color: #fff 
   }
    .suBtn {
        background-color: #19b698;
        border-radius: 5px;
        padding: 10px 20px 
   }
    .sub-heading-menu {
        font-size: 18px!important;
        font-weight: 700 
   }   
   
    div.ruby-wrapper .hidden-xs {
        visibility: hidden;
        display: none 
   }
    div.ruby-wrapper {
        position: absolute;
        width: 100%;
        top: 0;
        background: #111 
   }
    .ruby-wrapper .visible-xs {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0 
   }
    div.ruby-wrapper>ul {
        position: absolute;
        top: 50px;
        width: 100% 
   }
    ul.ruby-menu>li,ul.ruby-menu>li.ruby-menu-right {
        float: none 
   }
    ul.ruby-menu>li>a {
        padding: 0 65px;
        background: #19ad86 
   }
    ul.ruby-menu>li>a:only-child {
        padding: 0 65px 
   }
    ul.ruby-menu>li.ruby-active-menu-item>a:hover,ul.ruby-menu>li:hover>a,ul.ruby-menu>li>a:hover {
        background: #127e61 
   }
    ul.ruby-menu>li.ruby-active-menu-item>a {
        background: #159572 
   }
    ul.ruby-menu>li.ruby-menu-social>a>span {
        display: block 
   }
    ul.ruby-menu>li>ul,ul.ruby-menu>li>ul>li>ul,ul.ruby-menu>li>ul>li>ul>li>ul {
        position: relative;
        width: 100%;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-top: none 
   }
    ul.ruby-menu>li,ul.ruby-menu>li>ul>li,ul.ruby-menu>li>ul>li>ul>li,ul.ruby-menu>li>ul>li>ul>li>ul>li {
        position: relative 
   }
    ul.ruby-menu>li>ul>li>a,ul.ruby-menu>li>ul>li>ul>li>a,ul.ruby-menu>li>ul>li>ul>li>ul>li>a {
        width: 100%;
        line-height: 50px;
        padding: 0 80px;
        background: #d1d1d1 
   }
    ul.ruby-menu>li>ul>li:hover>a,ul.ruby-menu>li>ul>li>a:hover,ul.ruby-menu>li>ul>li>ul>li:hover>a,ul.ruby-menu>li>ul>li>ul>li>a:hover,ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>a,ul.ruby-menu>li>ul>li>ul>li>ul>li>a:hover {
        background: #d9d9d9;
        color: #111 
   }
    ul.ruby-menu>li>ul>li>ul,ul.ruby-menu>li>ul>li>ul>li>ul {
        left: auto;
        top: auto;
        z-index: auto 
   }
    ul.ruby-menu>li>ul>li.ruby-open-to-left>ul,ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left>ul {
        left: auto;
        right: auto 
   }
    ul.ruby-menu>li>ul>li>ul>li>a {
        background: #e5e5e5;
        padding: 0 95px 
   }
    ul.ruby-menu>li>ul>li>ul>li:hover>a,ul.ruby-menu>li>ul>li>ul>li>a:hover {
        background: #e1e1e1 
   }
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a {
        background: #efefef;
        padding: 0 110px 
   }
    ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>a,ul.ruby-menu>li>ul>li>ul>li>ul>li>a:hover {
        background: #fff 
   }
    ul.ruby-menu>li.ruby-menu-social>a {
        border: none 
   }
    ul.ruby-menu>li.ruby-menu-mega,ul.ruby-menu>li.ruby-menu-mega-blog,ul.ruby-menu>li.ruby-menu-mega-shop {
        position: relative 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div,ul.ruby-menu>li.ruby-menu-mega-shop>div,ul.ruby-menu>li.ruby-menu-mega>div {
        position: relative;
        top: auto;
        left: auto;
        border-top: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav {
        width: 100%;
        height: auto;
        left: auto;
        top: auto 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li {
        position: relative 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a {
        line-height: 50px;
        padding: 0 75px;
        background: #d1d1d1;
        color: #222 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>a {
        background: #bcbcbc 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        position: relative;
        width: 100%;
        min-height: auto;
        left: auto;
        top: auto;
        z-index: auto 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div {
        position: relative;
        text-align: left 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul {
        position: relative;
        border: none;
        font-size: 14px;
        margin-bottom: -1px 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li {
        position: relative;
        display: block;
        margin-top: -1px 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a {
        padding: 0 80px;
        line-height: 50px;
        max-height: 50px;
        text-transform: none;
        font-weight: 400;
        background: #d1d1d1;
        color: #222;
        border: none 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a {
        background: #bcbcbc 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>a,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover {
        background: #d9d9d9 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a {
        display: block 
   }
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div {
        position: relative;
        top: 0;
        z-index: 9999;
        margin: 0 
   }
    ul.ruby-menu>li.ruby-menu-social img {
        display: inline-table;
        position: absolute;
        top: 15px 
   }
    ul.ruby-menu>li.ruby-menu-social>a {
        width: 100% 
   }
    ul.ruby-menu>li.ruby-menu-social .fa {
        position: absolute;
        top: 17px 
   }
    ul.ruby-menu>li.ruby-menu-social span {
        line-height: 50px;
        padding: 0 25px 
   }
    span.ruby-dropdown-toggle {
        position: absolute;
        width: 50px;
        height: 50px;
        max-width: 50px;
        max-height: 50px;
        left: 0;
        top: 0;
        padding: 10px 17px;
        z-index: 1000000;
        cursor: pointer 
   }
    span.ruby-dropdown-toggle:after {
        font-family: FontAwesome;
        text-decoration: none;
        content: '\f107';
        color: #fff;
        font-size: 25px;
        right: 0 
   }
    span.ruby-dropdown-toggle.ruby-dropdown-toggle-rotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg) 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a:before,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before,ul.ruby-menu>li>a:before,ul.ruby-menu>li>ul>li>a:before,ul.ruby-menu>li>ul>li>ul>li>a:before,ul.ruby-menu>li>ul>li>ul>li>ul>li>a:before {
        content: "";
        width: 50px;
        height: 50px;
        background: #333;
        position: absolute;
        left: 0;
        top: 0 
   }
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a:before,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before,ul.ruby-menu>li>ul>li>a:before {
        background: #555 
   }
    ul.ruby-menu>li>ul>li>ul>li>a:before {
        background: #777 
   }
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:before {
        background: #999 
   }
    ul.ruby-menu,ul.ruby-menu>li.ruby-menu-mega-blog>div,ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div,ul.ruby-menu>li.ruby-menu-mega-shop>div,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div,ul.ruby-menu>li.ruby-menu-mega>div,ul.ruby-menu>li>ul,ul.ruby-menu>li>ul>li>ul,ul.ruby-menu>li>ul>li>ul>li>ul {
        max-height: 0;
        transform: scaleY(0);
        overflow: hidden 
   }
    ul.ruby-menu.ruby-mobile-sublevel-show,ul.ruby-menu>li.ruby-menu-mega-blog>div.ruby-mobile-sublevel-show,ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div.ruby-mobile-sublevel-show,ul.ruby-menu>li.ruby-menu-mega-shop>div.ruby-mobile-sublevel-show,ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div.ruby-mobile-sublevel-show,ul.ruby-menu>li.ruby-menu-mega>div.ruby-mobile-sublevel-show,ul.ruby-menu>li>ul.ruby-mobile-sublevel-show,ul.ruby-menu>li>ul>li>ul.ruby-mobile-sublevel-show,ul.ruby-menu>li>ul>li>ul>li>ul.ruby-mobile-sublevel-show {
        max-height: 20000px;
        transform: scaleY(1) 
   }
    div.ruby-grid>div.ruby-row div.ruby-row [class^=ruby-col] {
        margin-top: 0 
   }
    div.ruby-grid>div.ruby-row div.ruby-row:first-of-type [class^=ruby-col] {
        margin-top: 0 
   }
    div.ruby-grid.ruby-grid-lined>div.ruby-row:after {
        height: 0 
   }
    div.ruby-grid>div.ruby-row>[class^=ruby-col] {
        padding: 0 
   }
    div.ruby-grid>div.ruby-row>[class^=ruby-col] [class^=ruby-col] {
        padding-left: 0 
   }
    div.ruby-grid>div.ruby-row>[class^=ruby-col] [class^=ruby-col]:first-of-type {
        padding-left: 0 
   }
    div.ruby-grid>div.ruby-row div.ruby-col-1,div.ruby-grid>div.ruby-row div.ruby-col-10,div.ruby-grid>div.ruby-row div.ruby-col-11,div.ruby-grid>div.ruby-row div.ruby-col-12,div.ruby-grid>div.ruby-row div.ruby-col-2,div.ruby-grid>div.ruby-row div.ruby-col-3,div.ruby-grid>div.ruby-row div.ruby-col-4,div.ruby-grid>div.ruby-row div.ruby-col-5,div.ruby-grid>div.ruby-row div.ruby-col-6,div.ruby-grid>div.ruby-row div.ruby-col-7,div.ruby-grid>div.ruby-row div.ruby-col-8,div.ruby-grid>div.ruby-row div.ruby-col-9 {
        width: 100%;
        padding: 20px 30px 
   }
    div.ruby-grid.ruby-grid-lined>div.ruby-row>div[class^=ruby-col]:after {
        width: 0 
   }
    div.ruby-grid img {
        position: relative;
        max-width: 100%;
        height: auto;
        display: block 
   }
    div.ruby-grid>div.ruby-row::after {
        content: "";
        display: table;
        clear: both 
   }
    ul.ruby-menu.ruby-menu-dividers>li,ul.ruby-menu.ruby-menu-dividers>li.ruby-menu-social>a,ul.ruby-menu.ruby-menu-dividers>li:hover>a,ul.ruby-menu.ruby-menu-dividers>li>a,ul.ruby-menu.ruby-menu-dividers>li>a:hover {
        border: none 
   }
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li.ruby-active-menu-item>a {
        padding-left: 45px 
   }
    ul.ruby-menu>li.ruby-menu-social.ruby-menu-search>a {
        display: none 
   }   
   
   
   
}




@media (min-width: 501px) and (max-width: 850px) {
    #header {
        display: flex;
   }
    .header {
        height: auto;
   }
}
ul.primary, ul.secondary {
    display: none;
}
.filter-wrapper {
    display: none;
}
.filter-wrapper {
    display: none;
}
/* Menu for Mobile */





@media only screen and (max-width : 750px) {
    .mobile .tab_apply_button {
        display: none 
   }
}




@media only screen and (max-width: 1025px) {
    .toprihht {
        width: 35%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1180px) {
    .call {
        color: #000;
        display: block;
        float: left;
        height: auto;
        line-height: 18px;
        margin-bottom: 5px;
        margin-top: 5px;
        position: relative;
        width: 100%;
   }
    .toprihht {
        float: right;
        height: auto;
        margin-top: 0;
        text-align: right;
        width: 31%;
        margin-right: 5px;
   }
    .share {
        float: right;
        height: 35px;
        max-width: 160px;
        position: relative;
        width: 100%;
        margin-bottom: 3%;
   }
    .cart-icon #totalItems {
        position: absolute;
        top: -5px;
        height: 18px;
        width: 18px;
        line-height: 18px;
        background-color: #063e7b;
        color: #fff;
        font-weight: 700;
        text-align: center;
        border-radius: 50%;
        transform: scale(1);
        transition: transform 0.2s 0s;
        right: 105px;
   }
    .cart-icon {
        position: relative;
        top: 77px;
        left: 100px;
        padding: 0px;
        padding-right: 5px;
        background-image: url("../images/header/cart-smww.svg");
        background-repeat: no-repeat;
   }
    .logo {
        margin-right: 5px;
   }
    .share span {
        width: 25px;
   }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .cart-icon #totalItems {
        right: 103px;
   }
    .cart-icon {
        position: relative;
        top: 77px;
        left: 74px;
        padding: 0px;
        padding-right: 5px;
        background-image: url(../images/header/cart-smww.svg);
        background-repeat: no-repeat;
   }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
    .cart-icon #totalItems {
        position: absolute;
        top: -5px;
        height: 18px;
        width: 18px;
        line-height: 18px;
        background-color: #063e7b;
        color: #fff;
        font-weight: 700;
        text-align: center;
        border-radius: 50%;
        transform: scale(1);
        transition: transform 0.2s 0s;
        right: 0px;
    }
   .cart-icon {
    position: relative;
    top: 5px;
    left: 0px;
    padding: 0;
    padding-right: 5px;
    background-repeat: no-repeat;
    background-position: center;
}
}
