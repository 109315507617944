.order-details-page-print {
    text-align: left;
    font-family: Verdana, Arial, sans-serif;
    font-size: 1.1em;
  }
  
  .order-details-page-print-header img,
  .order-details-page-print-footer img {
    width: 100%;
  }
  
  .order-details-page-print-footer {
    background-color: #06407A;
    color: #fff;
    height: fit-content;
    font-size: 15.4px;
  }
  
  .order-details-page-print-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .order-details-page-print-table th,
  .order-details-page-print-table td {
    border: none;
    padding: 8px;
    font-size: 15px;
  }
  
  .order-details-page-print-table th {
    background-color: #fff;
    color: #06407A;
    padding:0px;
  }

  .order-details-page-print-table tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  .print-modal-cty-shp {
    text-align: right;
    align-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .product-order-invoice-details {
    display: flex;
    justify-content: space-between;
  }

  .line-for-print-page {
    border: none;
    height: 6px;
    color: #06407A;
    background-color: #06407A;
}

.print-page-footer-content {
  display: flex;
  justify-content: space-between;
  padding: 0% 2% 0% 2%;
}

.order-print-copyright {
  text-align: center;
  padding-bottom: 15px;
    margin-top: 0px;
}

.order-details-disc-shipp {
    font-size: 16px;
}

.order-details-disc-shipp th, .order-details-disc-shipp td {
  border: none;
  padding: 8px;
}

.order-details-disc-shipp td {
  text-align: right;
}

.print-page-payment-info {
  display: flex;
  justify-content: space-between;
}

.print-modal-total-print {
  margin-top: 0;
  width: 100%;
    background-color: #06407A;
    height: 35px;
    color: #fff;
}

.print-modal-total-paymt-info {
  margin-top: 0;
  color: #06407A;
    font-weight: bold;
    font-size: 20px;
}

.print-modal-thanks {
  display: flex;
  justify-content: space-between;
}

.print-thanks-left {
    width: 60%;
}

.print-thanks-right {
    width: 40%;
    text-align: center;
}

.print-cnt-ship-total {
    width: 71%;
    text-align: left !important;
}


@media print {
    .order-details-print-modal {
      display: block;
    }
  
    .order-details-print-modal-content {
      width: 100%;
      background-color: white;
      color: black;
      padding: 20px;
    }
  
    .printpage-close-button, .order-det-print-button {
      display: none;
    }
  
  }