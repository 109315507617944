#main{
  width:100%; 
  max-width:1170px;
  height:auto;
  margin:0 auto;
  display: flex;
}
#main h3, .block h2{
  color:#003c7d;
  font-size:18px;
  margin-top:25px;
  margin-bottom:10px;
  padding-left:10px;
  border-left:15px solid #f3ac3a;
  font-weight:0;}

  .user_total_amount {
    margin-top: 2%;
}
.user_total_amount {
  margin-top: 2%;
}

.quick-links-reg-button {
  background: #222 url(../../../public/images/overlay.png) repeat-x;
  display: inline-block;
  background-color: #004b85;
  padding: 5px 10px 6px;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border: 1px;
}

.user_total_amount {
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  background-color: #00c0ef;
  width: 200px;
  /* height: 40px; */
  color: #ffffff;
  font-size: 19px;
  vertical-align: middle;
  text-align: center;
  margin: 0;
  padding: 5px;
  float: left;
  margin-bottom: 10px;
}
.form-item,
.form-actions {
margin-top: 1em; 
margin-bottom: 1em;
}
ul.primarya {
border-bottom: 1px solid #bbb;
border-collapse: collapse;
height: auto;
line-height: normal;
list-style: none;
margin: 5px;
padding: 0 0 0 1em; /* LTR */
white-space: nowrap;
}
ul.primarya li {
display: inline;
}

label {
display: block;
font-weight: bold;
}
  .form-submit, .form-submit:visited {

    display: inline-block; 
    padding: 5px 10px 6px; 
    color: #fff; 
    text-decoration: none;
    border-radius: 6px; 
    -webkit-border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.6);
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.6);
    /*text-shadow: 0 -1px 1px rgba(0,0,0,0.25);*/
    border-bottom: 1px solid rgba(0,0,0,0.25);
    position: relative;
    cursor: pointer;
    border: 1px
  }
  
  .form-submit							{ margin-top: 5px; margin-bottom: 5px;   font-style: normal; }

  

  .form-submit, .form-submit:visited,
  .medium.form-submit, .medium.form-submit:visited 		{ font-size: 15px; 
   line-height: 1;  }
 
  .form-submit, .form-submit:visited		    { background-color: #004b85; }


.content {
display: none;
}
.show-content {
display: inline;
}



/*center*/
#main .center{ float: left;
  max-width: 670px;
  width: 54%;

}





#main .left-center{margin-left: 1%;
  max-width: 865px;
  width: 73%;}
#main .center-right{max-width:950px;margin-left: 1%;width: 98%;}




#main .right {
  max-width:280px;
  width: 24%;
  height:auto;
}




.tab {
overflow: hidden;
border: 1px solid #2d8cff !important;
border-radius: 5px;
border: 0px;
border-right: 1px solid rgba(45, 140, 255, .5);
background-color: #fff;
}


.center{
padding: 5px 10px;
/* background-color: #fff;*/
}


.img_right{
float: right;
margin: 0 0 10px 10px;
}



.user-image{
float:left;
}
.user-image .user-picture{
float:left;
}
.user-name-loc{
float:left;
width:450px;
width:auto;
font-size: 16px;
line-height: 18px;
    display: flex;
  flex-direction: column;
}
.user-name-loc span.user-name{
font-size: 32px;
line-height: 46px;
}
.user-name-bio span.user-bio{
font-size: 24px;
}
.user-scout-email{
font-size: 14px;
}
.user-sports{
font-size: 14px;
}

.user-bio p{word-wrap: break-word;}

.clear{

clear:both;
}


ul.user-opt-menu{

padding-left: 10px;
background-repeat: no-repeat;
text-decoration: underline;
}
ul.user-opt-menu li{
padding: 5px 0;
}



ul.primarya li a {
background-color: #ddd; 
border-color: #bbb; 
border-style: solid solid none solid;
border-width: 1px;
height: auto;
margin-right: 0.5em; /* LTR */
padding: 0 1em;
text-decoration: none;
}
ul.primarya li.active a {
background-color: #fff;
border: 1px solid #bbb;
border-bottom: 1px solid #fff;
}
ul.primarya li a:hover {
background-color: #eee;
border-color: #ccc;
border-bottom-color: #eee;
}


.page-view-myorder .panel-default>.panel-heading, .dp-style .panel-default>.panel-heading {
color: #333;
background-color: #f5f5f5;
border-color: #ddd;
}
.page-view-myorder .panel-heading, .dp-style .panel-heading {
padding: 10px 15px;
border-bottom: 1px solid transparent;
border-top-right-radius: 3px;
border-top-left-radius: 3px;
}
.page-view-myorder .panel-body, .dp-style .panel-body {
padding: 15px;
}
.page-view-myorder .table-bordered, #block-commerce-cart-cart .table-bordered, .dp-style .table-bordered {
border: 1px solid #ddd;
}
table {
border-collapse: collapse;
border-spacing: 0;
}
.page-view-myorder .table-striped>tbody>tr:nth-of-type(odd), #block-commerce-cart-cart .table-striped>tbody>tr:nth-of-type(odd), .dp-style .table-striped>tbody>tr:nth-of-type(odd) {
background-color: #f9f9f9;
}
.page-view-myorder .panel-default, .dp-style .panel-default {
border-color: #ddd;
}
.page-view-myorder .panel, .dp-style .panel {
margin-bottom: 20px;
background-color: #fff;
border: 1px solid transparent;
border-radius: 4px;
-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.page-view-myorder .table-bordered>thead>tr>th, .page-view-myorder .table-bordered>thead>tr>td, .page-view-myorder .table-bordered>tbody>tr>th, .page-view-myorder .table-bordered>tbody>tr>td, .page-view-myorder .table-bordered>tfoot>tr>th, .page-view-myorder .table-bordered>tfoot>tr>td, #block-commerce-cart-cart .table-bordered>thead>tr>th, #block-commerce-cart-cart .table-bordered>thead>tr>td, #block-commerce-cart-cart .table-bordered>tbody>tr>th, #block-commerce-cart-cart .table-bordered>tbody>tr>td, #block-commerce-cart-cart .table-bordered>tfoot>tr>th, #block-commerce-cart-cart .table-bordered>tfoot>tr>td, .dp-style .table-bordered>thead>tr>th, .dp-style .table-bordered>thead>tr>td, .dp-style .table-bordered>tbody>tr>th, .dp-style .table-bordered>tbody>tr>td, .dp-style .table-bordered>tfoot>tr>th, .dp-style .table-bordered>tfoot>tr>td {
border: 1px solid #ddd;
}
.page-view-myorder .panel-default, .dp-style .panel-default {
border-color: #ddd;
}
.page-view-myorder .table-bordered>thead>tr>th, .page-view-myorder .table-bordered>thead>tr>td, #block-commerce-cart-cart .table-bordered>thead>tr>th, #block-commerce-cart-cart .table-bordered>thead>tr>td, .dp-style .table-bordered>thead>tr>th, .dp-style .table-bordered>thead>tr>td {
border-bottom-width: 2px;
}
.page-view-myorder .table>thead>tr>th, #block-commerce-cart-cart .table>thead>tr>th, .dp-style .table>thead>tr>th {
vertical-align: bottom;
font-weight: normal;
border-bottom: 2px solid #ddd;
}
.page-view-myorder .table>thead>tr>th, .page-view-myorder .table>thead>tr>td, .page-view-myorder .table>tbody>tr>th, .page-view-myorder .table>tbody>tr>td, .page-view-myorder .table>tfoot>tr>th, .page-view-myorder .table>tfoot>tr>td, #block-commerce-cart-cart .table>thead>tr>th, #block-commerce-cart-cart .table>thead>tr>td, #block-commerce-cart-cart .table>tbody>tr>th, #block-commerce-cart-cart .table>tbody>tr>td, #block-commerce-cart-cart .table>tfoot>tr>th, #block-commerce-cart-cart .table>tfoot>tr>td, .dp-style .table>thead>tr>th, .dp-style .table>thead>tr>td, .dp-style .table>tbody>tr>th, .dp-style .table>tbody>tr>td, .dp-style .table>tfoot>tr>th, .dp-style .table>tfoot>tr>td {
padding: 8px;
line-height: 1.42857;
vertical-align: top;
border-top: 1px solid #ddd;
}
*{list-style:none;}
body{background:#f6f5f5;font-size:14px;font-family:"open sans", sans-serif, arial, verdana;color:#000}
.usertab-links:link, .usertab-links:visited {
  color: #000;
  text-decoration: none;
  font-size: 14px;
}

.usertab-links:hover, .usertab-links:active {
  color: #f3ac3a;
  text-decoration: underline;
  font-size: 14px;
}

.element-hidden {
display: none;
}
.element-invisible {
position: absolute !important; 
clip: rect(1px 1px 1px 1px); 
 clip: rect(1px, 1px, 1px, 1px); 
  overflow: hidden; 
 height: 1px;
}


.user-pp-cup {
  font-size: 8px !important;
  color: #2C99D2 !important;
  text-decoration: none !important;
}

.user-cancelled-payment {
  color: #2C99D2;
    text-decoration: none;
}
.quick_link_anchor {
  color: #000 !important;
  text-decoration: underline !important;
  font-size: 14px !important;
}
.order-details-page-quick-links li {
  padding: 5px 0;
  padding-left: 10px;
}
.ss_red {
  background:red;
  color:#fff;
}
@media only screen and (max-width: 600px) {

  #main {
    flex-direction: column;
    padding: 2%;
}

}  