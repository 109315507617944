.credit-card-change-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1170px;
  margin: 0 auto;
}

.cchange-content h2 {
  color: #003c7d;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.cchange-content {
  flex: 1;
  margin-right: 20px;
}

.cchange-table {
  width: 100%;
  margin-bottom: 20px;
}

.cchange-table, th, td {
  border: 1px solid #ddd;
  border-collapse: collapse;
  padding: 8px;
  text-align: left;
}

.cchange-table thead {
  background-color: #f9f9f9;
}

.cchange-table-bordered {
  border: 1px solid #ddd;
}

.cchange-table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.cchange-form-group {
  margin-bottom: 15px;
}

.cchange-form-group label {
  display: block;
  margin-bottom: 5px;
}

.edit-exp-month {
  width: 60px;
}

.cchange-form-group-charge {
  display: flex;
  margin-bottom: 15px;
}

.cchange-form-group-charge label {
  margin-bottom: 0px;
  display: inline-block;
  margin-top: 2px;
}

.edit-exp-year {
  width: 70px;
}

.cchange-expiration-date {
  display: flex;
  gap: 10px;
}

.cchange-form-actions {
  display: flex;
  gap: 10px;
}

.cchange-quick-links {
  width: 200px;
}

.cchange-quick-links h3 {
  color: #003c7d;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
  padding-left: 10px;
  border-left: 15px solid #f3ac3a;
}

.cchange-quick-links ul {
  list-style: none;
  padding: 0;
}

.cchange-quick-links li {
  margin-bottom: 5px;
}

.cchange-submit {
  background: #222 url(http://newsmww.preferati.net/sites/all/themes/smww/css/overlay.png) repeat-x;
  background-color: #004b85;
  display: inline-block;
  padding: 5px 10px 6px;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  position: relative;
  cursor: pointer;
  border: 1px;
}

.cc-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.cc-header-container h2 {
  margin: 0;
}

.cc-header-container a {
  text-decoration: none;
  color: #2C99D2;
  cursor: pointer;
}

.cchange-submit:hover {
  background-color: #2575cf;
}

.cc-edit-owner {
  width: 220px;
}

.cc-edit-number {
  width: 180px;
}

.cchange-maybelater {
  background-color: #999;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  position: relative;
  cursor: pointer;
  border: 1px;
  border-radius: 6px;
}

@media (max-width: 768px) {
  .credit-card-change-container {
    flex-direction: column;
    padding: 10px;
  }

  .cchange-content {
    margin-right: 0;
  }

  .cc-header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .cc-header-container h2,
  .cc-header-container a {
    margin-bottom: 10px;
  }



  .credit-card-form {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .credit-card-form .cchange-form-group {
    margin-bottom: 10px;
  }

  .credit-card-form label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .credit-card-form input,
  .credit-card-form select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
  }

  .credit-card-form .cchange-expiration-date {
    display: block;
  }

  .credit-card-form .edit-exp-month,
  .credit-card-form .edit-exp-year {
    width: 45%;
    margin-right: 5%;
    margin-bottom: 10px;
  }

  .credit-card-form .edit-exp-year {
    margin-right: 0;
  }

  .credit-card-form .cchange-form-actions {
    display: block;
  }

  .credit-card-form .cchange-form-actions button {
    width: 100%;
    margin-bottom: 10px;
  }

  .credit-card-form {
    display: block;
  }

  .credit-card-form {
    margin-left: 5px;
  }

 .cchange-form-group-charge input {
  width: auto;
 }

  .cchange-quick-links {
    width: 100%;
    margin-top: 20px;
  }

  .cchange-quick-links h3 {
    font-size: 16px;
    margin-top: 15px;
  }

  .cchange-quick-links ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .cchange-quick-links li {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .cchange-quick-links a {
    text-decoration: none;
    color: #2C99D2;
  }
}
